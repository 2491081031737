import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BasicCollapsible } from '@vp/swan';
import { categoryOptionsSelectorFactory, selectedL1SelectorFactory } from 'client/components/Gallery/Filters/util';
import { categoryByIdSelector } from '~/client/store/filterCategories';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { L2Filter } from 'client/components/Gallery/Filters/HierarchyFilters/L2Filter';
import { L1Filter } from 'client/components/Gallery/Filters/HierarchyFilters/L1Filter';

export interface PropTypes {
    filter: State.Filter;
}

export const FacetedTaxonomy = (props: PropTypes): JSX.Element | null => {
    const { filter } = props;

    const categoryId = (filter as State.CategoryFilter).categoryId.toString();

    const selectedL1Selector = useMemo(selectedL1SelectorFactory, []);
    const categoryOptionsSelector = useMemo(categoryOptionsSelectorFactory, []);
    const categoryById = useSelector(categoryByIdSelector);

    const { selectedCategoryId } = useSelector((state: State.GlobalState) => selectedL1Selector(state, categoryId));
    const { options, visible } = useSelector((state: State.GlobalState) => (
        categoryOptionsSelector(state, selectedCategoryId || categoryId)
    ));

    return (visible && options.length) ? (
        <BasicCollapsible
            aria-label={filter.title}
            className="filter"
            collapsibleId={filter.name}
            heading={filter.title}
        >
            {
                selectedCategoryId
                    ? (
                        <L2Filter
                            id={selectedCategoryId}
                            options={options}
                            refinementDimension={REFINEMENT_DIMENSION.CATEGORY}
                            title={categoryById(categoryId).title}
                        />
                    )
                    : (
                        <L1Filter
                            filter={filter as State.CategoryFilter}
                            options={options}
                            refinementDimension={REFINEMENT_DIMENSION.CATEGORY}
                        />
                    )
            }
        </BasicCollapsible>
    ) : null;
};

FacetedTaxonomy.displayName = 'FacetedTaxonomy';

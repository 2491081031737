import { Action } from 'redux';

export const SET_AUTH_DATA = 'SET_AUTH_DATA';

export interface AuthData {
    canonicalId?: string | null;
    accessToken?: string | null;
}

export interface AuthDataAction extends Action {
    type: string;
    payload: AuthData;
}

export const setAuthData = (authData: AuthData): AuthDataAction => ({
    payload: {
        ...authData,
    },
    type: SET_AUTH_DATA,
});

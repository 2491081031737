import { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';
import {
    BasicCollapsible,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';

import { useFilterOptions } from 'client/hooks/useFilterOptions';
import {
    buildRefinement,
    refinementAddAndRemove,
} from 'client/store/refinement';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

interface PropTypes {
    children: (o: RenderableFilterOption) => JSX.Element;
    CollapsibleProps?: ComponentProps<typeof BasicCollapsible>;
    filter: State.AttributeFilter;
    SelectionSetProps?: Omit<ComponentProps<typeof SelectionSet>, 'defaultSelectedValue' | 'variant' | 'selectedValue' | 'selectedValues' | 'onSelectedValueChange' | 'onSelectedValuesChange' | 'skin'>;
}

export const MultiSelectFilter = (props: PropTypes): JSX.Element => {
    const {
        children,
        CollapsibleProps = {},
        filter,
        SelectionSetProps = {},
    } = props;
    const { options, selected, visible } = useFilterOptions(filter);
    const dispatch = useDispatch();
    const redirectToL0 = useRedirectToL0();

    const handleSelectedValuesChange = (newSelectedValues: SelectedValues): void => {
        const toAdd: State.Refinement[] = [];
        const toRemove: State.Refinement[] = [];

        for (const [value, isSelected] of Object.entries(newSelectedValues)) {
            const refinement = buildRefinement(value, REFINEMENT_DIMENSION.ATTRIBUTE);

            (isSelected ? toAdd : toRemove).push(refinement);
        }

        if (redirectToL0(toAdd, toRemove)) {
            return;
        }

        dispatch(refinementAddAndRemove(toAdd, toRemove));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <>
            {!visible && null}
            {visible && (
                <BasicCollapsible
                    aria-label={filter.title}
                    className="filter"
                    collapsibleId={filter.name}
                    heading={filter.title}
                    {...CollapsibleProps}
                >
                    <SelectionSet
                        aria-label={filter.title}
                        className="selection-set-layout-override"
                        selectedValues={selected}
                        skin="simple-column"
                        variant="multi-select"
                        onSelectedValuesChange={handleSelectedValuesChange}
                        {...SelectionSetProps}
                    >
                        {options.map((o) => (
                            <div className="selection-set-item" key={o.value}>
                                <SelectionSetInput
                                    disabled={o.disabled}
                                    value={o.value}
                                >
                                    <SelectionSetLabel>
                                        {children(o)}
                                    </SelectionSetLabel>
                                </SelectionSetInput>
                            </div>
                        ))}
                    </SelectionSet>
                </BasicCollapsible>
            )}
        </>
    );
};

MultiSelectFilter.displayName = 'MultiSelectFilter';

import {
    useCallback, useEffect, useRef, useState,
} from 'react';

const isSafari = (): boolean => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// To understand DPR follow https://datacadamia.com/web/css/pixel_ratio
// https://web.dev/high-dpi/#calculating-the-device-pixel-ratio
// To resolve accessibility issue UWA-781 for quick view image zooming when user use browser zoom
export const useBrowserZoomClassName = (): string => {
    const [browserZoomClassName, setBrowserZoomClassName] = useState<string>('browser-zoom-100');
    const initialDPRDelta = useRef<number|null>();
    const isHDR = useRef<boolean|null>();

    const callback = useCallback((): void => {
        let zoom = 1;

        if (window.devicePixelRatio && !isSafari()) {
            if (isHDR.current) {
                zoom = (window.devicePixelRatio * 100) / 2;
            } else {
                // To get the exact zoom percentage subtract the DPR delta with current DPR
                // Note: delta can't be in negative if it is then consider it as 0, means we already at 100% or below it
                const delta = initialDPRDelta.current && initialDPRDelta.current > 0 ? initialDPRDelta.current : 0;
                const exactZoomLevel = window.devicePixelRatio - delta;

                // Get zoom in percentage
                zoom = exactZoomLevel * 100;
            }
        } else {
            zoom = Math.ceil((window.screen.availWidth / document.documentElement.clientWidth) * 100);
        }

        let className = 'browser-zoom-100';

        if (zoom >= 500) {
            className = 'browser-zoom-500';
        } else if (zoom >= 400) {
            className = 'browser-zoom-400';
        } else if (zoom >= 300) {
            className = 'browser-zoom-300';
        } else if (zoom >= 250) {
            className = 'browser-zoom-250';
        } else if (zoom >= 200) {
            className = 'browser-zoom-200';
        } else if (zoom >= 175) {
            className = 'browser-zoom-175';
        } else if (zoom >= 150) {
            className = 'browser-zoom-150';
        } else if (zoom >= 125) {
            className = 'browser-zoom-125';
        } else if (zoom >= 110) {
            className = 'browser-zoom-110';
        }

        setBrowserZoomClassName(className);
    }, [initialDPRDelta, isHDR]);

    useEffect(() => {
        if (typeof isHDR.current === 'undefined') {
            initialDPRDelta.current = window.devicePixelRatio - 1;
            isHDR.current = window.devicePixelRatio >= 2;
        }

        callback();

        window.addEventListener('resize', callback);
        return (): void => {
            window.removeEventListener('resize', callback);
        };
    }, [callback]);

    return browserZoomClassName;
};

import {
    buildIdentityState, IdentityState, init, webAuth,
} from 'client/utils/auth';
import {
    ComponentProps, createContext, useEffect, useState,
} from 'react';

export const AuthContext = createContext<IdentityState | null>(null);

export const AuthProvider = (
    props: Omit<ComponentProps<typeof AuthContext.Provider>, 'value'> & {
        locale: i18n.Locale;
        signoutRedirectUrl: string;
        onAuthUpdate?: (authData: { canonicalId?: string; accessToken?: string }) => void;
    },
): JSX.Element => {
    const {
        locale, signoutRedirectUrl, onAuthUpdate, ...rest
    } = props;
    const [identity, setIdentity] = useState<IdentityState | null>(null);

    useEffect(() => {
        const handleIdentityStateUpdate = (identityState: IdentityState | null): void => {
            setIdentity(identityState);

            if (identityState && onAuthUpdate) {
                onAuthUpdate({
                    canonicalId: identityState.canonicalId ?? undefined,
                    accessToken: identityState.accessToken ?? undefined,
                });
            }
        };

        const task = async (): Promise<void> => {
            await init(locale, signoutRedirectUrl);

            webAuth.onUserIdentityUpdate(() => {
                const identityState = buildIdentityState(webAuth);

                handleIdentityStateUpdate(identityState);
            });

            const identityState = buildIdentityState(webAuth);

            handleIdentityStateUpdate(identityState);
        };

        task();
    }, [locale, signoutRedirectUrl, onAuthUpdate]);

    return <AuthContext.Provider {...rest} value={identity} />;
};

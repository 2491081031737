import {
    Children, cloneElement, isValidElement, ReactChild, ReactNode,
} from 'react';
import classnames from 'classnames';
import { useBrowserZoomClassName } from 'client/hooks/useBrowserZoomClassName';

type Props = {
    children: ReactNode
}
export const BrowserZoom = ({ children }: Props): JSX.Element => {
    const browserZoomClassName = useBrowserZoomClassName();

    const modifyChildren = (child: ReactChild): ReactChild => {
        if (isValidElement(child)) {
            const className = classnames((child.props as { className: string; })?.className, browserZoomClassName);

            return cloneElement(child, { className } as { className: string; });
        }

        return child;
    };

    return (
        <>{Children.map(children as ReactChild[], modifyChildren)}</>
    );
};

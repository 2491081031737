import {
    lazy, Suspense, useCallback, useDeferredValue,
    useEffect,
} from 'react';
import { QuickView } from 'client/components/common/QuickView';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GalleryQuickViewSpinner } from 'client/components/Gallery/PreviewArea/GalleryQuickView/GalleryQuickViewSpinner';
import { getQuickViewId } from '~/client/store/debug';
import { resetQuickViewId } from '~/client/store/debug/actions';
import { useGalleryBaseURL } from '~/client/hooks/useGalleryBaseURL';
import { useGalleryDesignIdFromUrl } from '~/client/hooks/useGalleryDesignId';
import { tileEntityByIdSelector } from '~/client/store/tileEntity';

const RouteableQuickView = lazy(() => import('client/components/Gallery/PreviewArea/RouteableQuickView'));

export const QuickViewModal = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const designId = useGalleryDesignIdFromUrl();
    const galleryBaseURL = useGalleryBaseURL();
    const quickViewDesignID = useSelector(getQuickViewId);
    const tileEntityById = useSelector(tileEntityByIdSelector);

    const deferredDesignId = useDeferredValue(designId);

    const shouldOpenQuickView = !!designId;

    const handleOnRequestDismiss = useCallback((): void => {
        // Wrapped in setTimeout in order to improve INP performance
        setTimeout(() => {
            dispatch(resetQuickViewId());
        });

        navigate({
            ...location,
            pathname: galleryBaseURL,
        });
    }, [location, galleryBaseURL, navigate, dispatch]);

    useEffect(() => {
        if (!quickViewDesignID) {
            return;
        }

        const qvTile = tileEntityById(quickViewDesignID);
        const newUrl = new URL(window.location.href);

        if (!qvTile || newUrl.pathname.includes('/qv/')) {
            return;
        }

        newUrl.searchParams.delete('quickView');
        newUrl.pathname = `${newUrl.pathname}/qv/${quickViewDesignID}`;

        const qvUrl = `${newUrl.pathname}${newUrl.search}`;

        navigate(qvUrl, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickViewDesignID]);

    return (
        <QuickView
            onlyRenderWhenOpen
            id={`quickview-${designId}`}
            isOpen={shouldOpenQuickView}
            onRequestDismiss={handleOnRequestDismiss}
        >
            {deferredDesignId && (
                <Suspense fallback={<GalleryQuickViewSpinner />}>
                    <RouteableQuickView designId={deferredDesignId} />
                </Suspense>
            )}
            {!deferredDesignId && (
                <GalleryQuickViewSpinner />
            )}
        </QuickView>
    );
};

export const personalizedTextToDpcText = (
    personalizedText: Gallery.Models.Personalization.PersonalizedTextData,
): Gallery.Models.Personalization.DpcText[] | undefined => {
    const dpcTextArray: Gallery.Models.Personalization.DpcText[] = Object.keys(personalizedText).map((key) => ({
        textField: {
            purposeName: key,
            text: personalizedText[key],
        },
    }));

    return dpcTextArray.length > 0 ? dpcTextArray : undefined;
};

interface buildDpcArgs {
    text?: Gallery.Models.Personalization.DpcText[],
    images?: Gallery.Models.Personalization.DpcImage[],
}

export const buildDesignPersonalizationContext = ({ text, images }: buildDpcArgs): Gallery.Models.Personalization.DesignPersonalizationContext => {
    if ((!text || text.length === 0) && (!images || images.length === 0)) {
        return undefined;
    }
    const newContext: Gallery.Models.Personalization.DesignPersonalizationContext = {
        designPersonalizationContextVersion: 1,
    };

    if (text && text.length > 0) {
        newContext.text = text;
    }

    if (images && images.length > 0) {
        newContext.images = images;
    }

    return newContext;
};

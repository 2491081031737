import { useSelector } from 'react-redux';
import { igpCategoriesSelector } from 'client/store/igpCategories';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { useMemo } from 'react';

export const useExcludeIgpCategories = (refinements: Refinements.Refinements): Refinements.RefinementItem[] => {
    const igpCategories = useSelector(igpCategoriesSelector);

    const filteredRefinements = useMemo(() => {
        const refinementsToShow: Refinements.RefinementItem[] = [];

        for (const key in refinements) {
            if (Object.hasOwn(refinements, key)) {
                const isRefinementToShow = refinements[key].dimension !== REFINEMENT_DIMENSION.CATEGORY
                    || !igpCategories.includes(refinements[key].value);

                if (isRefinementToShow) {
                    refinementsToShow.push(refinements[key]);
                }
            }
        }
        return refinementsToShow;
    }, [igpCategories, refinements]);

    return filteredRefinements;
};

import {
    Box,
    Button,
    Divider,
    FormField,
    FormInputGroup,
    Spinner,
    StandardForm,
    TextInput,
    TextInputFloatingLabel,
    TextInputWithFloatingLabel,
} from '@vp/swan';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    useState, useEffect,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { useBrandContext } from '@vp/react-brand';
import { RenderProperty } from 'shared/renderProperties';
import { useTranslations } from '~/client/hooks/useTranslations';
import { scrollToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { personalizedTextUpdate, personalizedImageUpdate } from 'client/store/personalization/actions';
import { designPersonalizationContextSelector } from 'client/store/personalization/selectors';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { contentUpdate } from 'client/store/content';
import {
    IMAGE_UPLOAD_STATES,
} from 'client/constants';
import { mobilePersonalizationOpenState } from '~/client/atoms/mobilePersonalizationOpenAtom';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useAuth } from '~/client/hooks/useAuth';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import { PERSONALIZATION_UX_BRAND_VARIATIONS } from '~/experiments/Personalization/constants';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { splitFullName } from './splitFullName';
import { dpcToBrandContent, dpcToTextFields } from './dpcConverters';
import { PURPOSE_NAMES, TEXT_INPUT_DEBOUNCE_TIME_MS } from './constants';
import { TemplateField } from './purposeHelpers';
import { PersonalizationImageModal } from './PersonalizationImageModal';
import { usePurposeNames } from '../../Header/Personalization/hooks/usePurposeNames';
import { useRestoreDpc } from '../../Header/Personalization/hooks/useRestoreDpc';
import { BrandSelector } from './BrandSelector';
import { useConvertUploadsToDpc } from '../../Header/Personalization/hooks/useConvertUploadsToDpc';

export const PersonalizationFilter = (): JSX.Element => {
    const setPersonalizationModalOpen = useSetRecoilState(mobilePersonalizationOpenState);
    const {
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
    } = useContext(PersonalizationReactContext);
    const [textDebounceFns, setTextDebounceFns] = useState<Record<string, NodeJS.Timeout>>({});
    const dispatch = useDispatch();
    const uploadState = useRecoilValue(imageUploadState);
    const [textUpdateLoading, setTextUpdateLoading] = useState<Record<string, boolean>>({});
    const analytics = useAnalytics();
    const auth = useAuth();
    const brandcontext = useBrandContext();
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [brandId, setBrandId] = useState<string | undefined>();
    const [brandNewlyCreated, setBrandNewlyCreated] = useState(false);
    const { createBrand, updateBrand } = brandcontext.api;
    const localize = useTranslations();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const shouldStoreToBrand = booleanRenderProperty(RenderProperty.PersonalizationStoreToBrand);
    const purposeNames = usePurposeNames();
    const selectedDpcImages = useConvertUploadsToDpc();
    const selectedUploads = useRecoilValue(selectedUploadsState);

    // Used for persisting dpc data
    const galleryId = useSelector(getGalleryIdSelector);

    const dpc = useSelector(designPersonalizationContextSelector);
    const personalizedText = dpcToTextFields(purposeNames, dpc);

    const isExperimentActive = useExperimentation();
    const isBrandExperimentEnabled = isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled);

    useRestoreDpc();

    useEffect(() => {
        // check for control variation
        if (!isBrandExperimentEnabled) {
            isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control);
        }
    }, [isBrandExperimentEnabled, isExperimentActive]);

    const createOrUpdateBrand = useCallback(async (): Promise<void> => {
        const brandContent = dpcToBrandContent(dpc);

        // Don't do anything if there is no content
        if (Object.keys(brandContent).length === 0) {
            return;
        }

        // Only create a brand if there isn't an existing one
        if (!brandId && brandcontext.brands.length === 0) {
            try {
                setIsLoadingBrand(true);
                const newBrand = await createBrand(brandContent);

                setBrandId(newBrand.brandId);
                setBrandNewlyCreated(true);
            } finally {
                setIsLoadingBrand(false);
            }
        } else if (brandId) {
            try {
                setIsLoadingBrand(true);
                // patchBrand will update only data specified in request
                // updateBrand will replace all data. TODO: Switch to patchBrand when it works
                updateBrand({
                    brandId,
                    isActive: true,
                    isFactual: true,
                    ...brandContent,
                });
            } finally {
                setIsLoadingBrand(false);
            }
        }
        // Including the brand functions here causes infinite network calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, dpc, brandcontext.isInitialized]);

    useEffect(() => {
        if (!shouldStoreToBrand || isLoadingBrand || !brandcontext.isInitialized) {
            return;
        }

        // creating a brand changes the brandId and triggers a redundant brand update
        if (brandNewlyCreated) {
            setBrandNewlyCreated(false);
            return;
        }

        createOrUpdateBrand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, brandcontext.isInitialized, createOrUpdateBrand, isLoadingBrand, shouldStoreToBrand]);

    const updatePersonalizedText = useCallback((newTextInput: string, purposeName: PURPOSE_NAMES) => {
        if (newTextInput !== '') {
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                dispatch(personalizedTextUpdate({
                    ...personalizedText,
                    [purposeName]: newTextInput,
                    ...splitFullName(newTextInput),
                }, galleryId));
            } else {
                dispatch(personalizedTextUpdate({ ...personalizedText, [purposeName]: newTextInput }, galleryId));
            }
        } else {
            // Reset field (and derived fields) back to template placeholder
            // eslint-disable-next-line no-lonely-if
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                const {
                    fullname: _, firstname: _1, lastname: _2, ...newPersonalizedText
                } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            } else {
                const { [purposeName]: _, ...newPersonalizedText } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            }
        }

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Personalized',
            eventDetail: `Text Personalized: ${purposeName}`,
            ...analytics.getPageProperties(),

        });
    }, [analytics, dispatch, galleryId, personalizedText]);

    const handleTextChange = (templateField: TemplateField, event: React.ChangeEvent<HTMLInputElement>): void => {
        const { purposeName } = templateField;
        const newText = event.target.value;

        if (isSmallScreen()) {
            setMobileTextfieldValues?.({ ...mobileTextfieldValues, [purposeName]: newText });
            return;
        }

        setTextfieldValues?.({ ...textfieldValues, [purposeName]: newText });

        const debounceFn = textDebounceFns[purposeName];

        if (debounceFn) {
            clearTimeout(debounceFn);
            setTextUpdateLoading((prevState) => ({
                ...prevState,
                [purposeName]: false,
            }));
        }

        setTextDebounceFns({
            ...textDebounceFns,
            [purposeName]: setTimeout(() => {
                updatePersonalizedText(newText, purposeName);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }, TEXT_INPUT_DEBOUNCE_TIME_MS),
        });
        setTextUpdateLoading((prevState) => ({
            ...prevState,
            [purposeName]: true,
        }));
    };

    const handleEnterPress = (purposeName: PURPOSE_NAMES, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && textfieldValues[purposeName] !== personalizedText[purposeName]) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Text Personalized - Enter Key Submit',
                eventDetail: `Text Personalized: ${purposeName}`,
                ...analytics.getPageProperties(),

            });

            if (textDebounceFns[purposeName]) {
                clearTimeout(textDebounceFns[purposeName]);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }

            updatePersonalizedText(textfieldValues[purposeName], purposeName);
        }
    };

    const trackTextFocus = (purposeName: string): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Field Selected',
            eventDetail: `Text Field Selected: ${purposeName}`,
            ...analytics.getPageProperties(),
        });
    };

    const handleMobileSubmitPersonalization = (): void => {
        // updatePersonalizedText() doesn't work for bulk text updates, only single field updates
        const newPersonalizedText: Record<string, string> = {};

        if (mobileTextfieldValues) {
            Object.keys(mobileTextfieldValues).forEach((purposeName) => {
                const textfieldValue = mobileTextfieldValues[purposeName];

                if (textfieldValue !== '') {
                    if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                        const splitName = splitFullName(textfieldValue);

                        newPersonalizedText.firstname = splitName.firstname;
                        if (splitName.lastname) {
                            newPersonalizedText.lastname = splitName.lastname;
                        }
                    }
                    newPersonalizedText[purposeName] = textfieldValue;
                }

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Text Personalized - Mobile Submit Button',
                    eventDetail: `Text Personalized: ${purposeName}`,
                    ...analytics.getPageProperties(),
                });
            });
        }

        dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
        batch(() => {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization modal images applied',
                eventDetail: `${selectedUploads.length} Personalization modal images applied`,
                ...analytics.getPageProperties(),
            });
            dispatch(personalizedImageUpdate(selectedDpcImages, galleryId));
            dispatch(contentUpdate());
        });

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization submit button clicked',
            eventDetail: `Personalization submit button clicked`,
            ...analytics.getPageProperties(),
        });

        setPersonalizationModalOpen(false);
        setTimeout(() => {
            scrollToElement(galleryHeaderId, { behavior: 'auto', block: 'start', inline: 'nearest' });
        }, 100);
    };

    const panelGap = useMemo(() => (isSmallScreenPersonalizationEnabled ? 'to-actions' : 'between-actions'), [isSmallScreenPersonalizationEnabled]);

    return auth ? (
        <section aria-label="personalize" className="personalization-container">
            {isBrandExperimentEnabled && <BrandSelector />}
            <Box
                className="personalization-upload-button-container"
                mb={purposeNames.length ? panelGap : 0}
            >
                <PersonalizationImageModal />
            </Box>
            <StandardForm autoComplete="off">
                {purposeNames.map((p, i, { length }) => (
                    <FormField className="personalization-form-field" key={p.purposeName}>
                        <FormInputGroup mb={i !== length - 1 ? panelGap : 0}>
                            {isSmallScreenPersonalizationEnabled && (
                                <TextInputWithFloatingLabel>
                                    <TextInput
                                        className="personalization-text-input"
                                        placeholder={p.placeholder}
                                        value={mobileTextfieldValues[p.purposeName] || ''}
                                        onChange={(e): void => handleTextChange(p, e)}
                                        onFocus={(): void => trackTextFocus(p.purposeName)}
                                    />
                                    <TextInputFloatingLabel>
                                        {p.placeholder}
                                    </TextInputFloatingLabel>
                                </TextInputWithFloatingLabel>
                            )}
                            {!isSmallScreenPersonalizationEnabled && (
                                <TextInputWithFloatingLabel>
                                    <TextInput
                                        className={textUpdateLoading[p.purposeName] ? 'personalization-text-input-loading' : undefined}
                                        placeholder={p.placeholder}
                                        value={textfieldValues[p.purposeName] || ''}
                                        onChange={(e): void => handleTextChange(p, e)}
                                        onFocus={(): void => trackTextFocus(p.purposeName)}
                                        onKeyDown={(e): void => handleEnterPress(p.purposeName, e)}
                                    />
                                    {textUpdateLoading[p.purposeName] && <Spinner accessibleText={localize('Loading')} className="personalization-text-input-spinner" size="tiny" />}
                                    <TextInputFloatingLabel>
                                        {p.placeholder}
                                    </TextInputFloatingLabel>
                                </TextInputWithFloatingLabel>
                            )}
                        </FormInputGroup>
                    </FormField>
                ))}
            </StandardForm>
            {isSmallScreenPersonalizationEnabled
                && (
                    <>
                        <Divider mb={6} mt={6} />
                        <Button
                            disabled={uploadState.status === IMAGE_UPLOAD_STATES.LOADING}
                            skin="primary"
                            width="full-width"
                            onClick={handleMobileSubmitPersonalization}
                        >
                            {localize('PersonalizationSubmitButtonText')}
                        </Button>
                    </>
                )}
        </section>
    ) : <Box loadingShimmer className="personalization-container-skeleton" mb={5} />;
};

import { useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button, ButtonProps } from '@vp/swan';
import { usePersonalizationFlyoutOpen } from 'src/client/components/Gallery/Header/Personalization/hooks';
import { useTranslations } from 'client/hooks/useTranslations';
import { useRecoilValue } from 'recoil';
import { personalizedImageUpdate } from 'client/store/personalization/actions';
import { contentUpdate } from 'client/store/content';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { getGalleryIdSelector } from '~/client/store/config';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { useConvertUploadsToDpc } from '../../Header/Personalization/hooks/useConvertUploadsToDpc';

type PersonalizationImageModalPreviewButtonProps = Omit<ButtonProps, 'ref'> & {
    selectedImagesAreAlreadyApplied: boolean,
}

export const PersonalizationImageModalPreviewButton = (props: PersonalizationImageModalPreviewButtonProps): JSX.Element => {
    const { selectedImagesAreAlreadyApplied } = props;
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const selectedDpcImages = useConvertUploadsToDpc();
    const localize = useTranslations();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const [, setUploadModalOpen] = usePersonalizationFlyoutOpen();
    const galleryId = useSelector(getGalleryIdSelector);

    const applySelectedImagesText = useMemo(() => {
        const numImages = selectedUploads.length;

        if (numImages > 1) {
            return localize('PersonalizationModalPluralPreviewImageButtonText').replace('{{number}}', `${numImages}`);
        }

        if (numImages === 1) {
            return localize('PersonalizationModalSingularPreviewImageButtonText');
        }

        if (numImages === 0 && !selectedImagesAreAlreadyApplied) {
            return localize('PersonalizationModalClearPreviewButtonText');
        }

        return localize('PersonalizationModalGenericPreviewImageButtonText');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUploads.length]);

    const handleApplySelectedUploads = (): void => {
        if (!isSmallScreen()) {
            batch(() => {
                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal images applied',
                    eventDetail: `${selectedUploads.length} Personalization modal images applied`,
                    ...analytics.getPageProperties(),
                });
                dispatch(personalizedImageUpdate(selectedDpcImages, galleryId));
                dispatch(contentUpdate());
            });
        } else {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Mobile image upload modal preview button clicked',
                eventDetail: `${selectedUploads.length} images selected`,
                ...analytics.getPageProperties(),
            });
        }

        setUploadModalOpen(false);
    };

    return (
        <Button
            aria-label={applySelectedImagesText}
            className="apply-uploads-button"
            skin="primary"
            onClick={handleApplySelectedUploads}
            {...props}
        >
            {applySelectedImagesText}
        </Button>
    );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    basicImageFileTypeMappings,
    LocalizationProvider,
    UploadConfigurationProvider,
    UploadManagerProvider,
    UploadManagerProviderProps,
} from '@design-stack-vista/upload-components';
import { useDispatch, useSelector } from 'react-redux';
import {
    ComponentProps,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import config from 'config';
import { BrandProfileProvider } from '@vp/react-brand';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { LOCAL_STORAGE_KEYS, MAX_PHOTO_UPLOADS, IMAGE_UPLOAD_STATES } from 'client/constants';
import { getLogger } from 'client/utils/gallery/logger';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { designPersonalizationContextSelector } from 'client/store/personalization/selectors';
import { SubBrands, Tenants, VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { designPersonalizationContextUpdate, personalizedImageUpdate } from '~/client/store/personalization/actions';
import { contentUpdate } from '~/client/store/content';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { shouldRestorePhotosState } from '~/client/atoms/shouldRestorePhotosAtom';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { MAX_UPLOADS } from './constants';
import { useExpectImagesToBePhotos } from './useSpecifyImageCopy';

const LOGGER = getLogger();

export const PersonalizationWrapper = (props: ComponentProps<typeof UploadConfigurationProvider>): JSX.Element | null => {
    const {
        children,
    } = props;

    const analytics = useAnalytics();
    const defaultToPhoto = useExpectImagesToBePhotos();
    const [selectedUploads, setSelectedUploads] = useRecoilState(selectedUploadsState);
    const designPersonalizationContext = useSelector(designPersonalizationContextSelector);
    const dispatch = useDispatch();
    const galleryId = useSelector(getGalleryIdSelector);
    const [localUploadedAssets, setLocalUploadedAssets] = useState<VistaAsset[]>();
    const setImageUploadState = useSetRecoilState(imageUploadState);
    const auth = useAuth();
    const [textfieldValues, setTextfieldValues] = useState<Record<string, string>>({});
    const [mobileTextfieldValues, setMobileTextfieldValues] = useState<Record<string, string>>({});
    const isClientSide = useMemo(() => typeof window !== 'undefined', []);
    const localStorageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPC + galleryId;
    const setShouldRestorePhotos = useSetRecoilState(shouldRestorePhotosState);
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const [isSmallScreenPersonalizationEnabled,
        setIsSmallScreenPersonalizationEnabled] = useState(booleanRenderProperty(RenderProperty.ShowPersonalizationUI));
    const localize = useTranslations();

    const brandAuth = useMemo(() => (auth?.authorizationHeader ? { type: '', token: auth.authorizationHeader } : ''), [auth]);

    useEffect(() => {
        if (!isSmallScreen()) {
            setIsSmallScreenPersonalizationEnabled(false);
        }
    }, []);

    const handleAssetDelete = async (asset: VistaAsset): Promise<void> => {
        // Remove deleted asset from selected photos to prevent selection state from desyncing
        const newSelectedUploads = selectedUploads.filter((upload) => upload.assetId !== asset.data?.id);

        setSelectedUploads(newSelectedUploads);

        // If applied photos doesn't include the deleted asset, don't do anything
        if (!(designPersonalizationContext?.images?.find((i) => i.image.assetId === asset.data?.id))) {
            return;
        }

        const newDpcImages = designPersonalizationContext.images.filter(
            (i) => i.image.assetId !== asset.data?.id,
        );

        dispatch(personalizedImageUpdate(newDpcImages, galleryId));
        dispatch(contentUpdate());

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Deleted',
            eventDetail: `Image Deleted`,
            ...analytics.getPageProperties(),
        });
    };

    const handleUploadError = useCallback((error?: Error, uploadedAssets?: VistaAsset[]): void => {
        setImageUploadState({ status: IMAGE_UPLOAD_STATES.ERROR, message: error?.message });

        LOGGER.error(`Failed to upload image(s): ${error}`, error, { uploadedAssets });

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Error',
            eventDetail: `Image Upload Error: ${error}`,
            ...analytics.getPageProperties(),
        });
    }, [analytics, setImageUploadState]);

    const handleUploadComplete = useCallback(() => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Success',
            eventDetail: 'Image Upload Success',
            ...analytics.getPageProperties(),
        });
    }, [analytics]);

    const handleUploadStart: UploadManagerProviderProps['onUpload'] = async (selectedAssets, _uploadedBlobs, nonUploadedBlobs) => {
        setImageUploadState({ status: IMAGE_UPLOAD_STATES.LOADING });

        // Do nothing if there are no selected assets or there are any non-uploaded files
        if (!selectedAssets.length || nonUploadedBlobs.length) {
            setImageUploadState({ status: IMAGE_UPLOAD_STATES.READY });
            return;
        }

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Started',
            eventDetail: `Image Upload Started`,
            ...analytics.getPageProperties(),
        });

        /**
         * Wait for all files to finish uploading
         * onError handler could be called for any of these uploads which doesn't result in a thrown error
         */
        const uploadedAssets = (await Promise.all(selectedAssets)).filter((asset) => !!asset) as VistaAsset[];
        const allAssetsUploaded: boolean = uploadedAssets.length === selectedAssets.length;

        /**
         * If not all files resolve with an asset, don't apply any of the uploads as any failed uploads
         * would have triggered an error state.
         */
        if (!allAssetsUploaded) {
            setImageUploadState({ status: IMAGE_UPLOAD_STATES.ERROR });
            return;
        }

        setLocalUploadedAssets(uploadedAssets);
    };

    // Add uploaded images to selected images after they finish uploading
    useEffect(() => {
        const convertAndSetPreviews = async (uploadedAssets: VistaAsset[], photosToSelect: number): Promise<void> => {
            const newUploads = uploadedAssets.slice(0, photosToSelect).map((asset) => ({ assetId: asset.data?.id ?? '' }));

            setSelectedUploads([...selectedUploads, ...newUploads]);

            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
                eventLabel: 'Image Upload Completed',
                eventDetail: `Image Upload(s) and Processing Completed`,
                ...analytics.getPageProperties(),
            });
        };

        if (localUploadedAssets) {
            try {
                const photosToSelect = Math.max(0, MAX_UPLOADS - selectedUploads.length);

                convertAndSetPreviews(localUploadedAssets, photosToSelect);
            } catch (e) {
                handleUploadError(e as Error, localUploadedAssets);
            } finally {
                setImageUploadState({ status: IMAGE_UPLOAD_STATES.READY });
                setLocalUploadedAssets(undefined);
            }
        }
    }, [analytics,
        defaultToPhoto,
        handleUploadError,
        localUploadedAssets,
        setImageUploadState,
        selectedUploads,
        setSelectedUploads,
    ]);

    const uploadAuthProvider = useMemo(() => (auth ? {
        getAuthHeaders: async (): Promise<{ Authorization: string }> => ({ Authorization: `Bearer ${auth?.accessToken}` }),
    } : undefined), [auth]);

    useEffect(() => {
        const localStorageData = localStorage.getItem(localStorageKey);

        if (!localStorageData || !isClientSide) {
            return;
        }

        const restoredDpc: Gallery.Models.Personalization.DesignPersonalizationContext = JSON.parse(localStorageData);

        setShouldRestorePhotos(true);

        // Restore Redux state
        dispatch(designPersonalizationContextUpdate(restoredDpc));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientSide]);

    const contextValue = useMemo(() => ({
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
    }), [isSmallScreenPersonalizationEnabled, mobileTextfieldValues, textfieldValues]);

    return (
        <PersonalizationReactContext.Provider value={contextValue}>
            <UploadConfigurationProvider
                maxUploads={MAX_PHOTO_UPLOADS}
                supportedFileTypes={basicImageFileTypeMappings.filter((type) => type.fileType !== 'application/pdf')}
            >
                <UploadManagerProvider
                    assetsExpireOn={process.env.NODE_ENV === 'production' ? 'never' : 'P1D'}
                    authProvider={uploadAuthProvider}
                    experience="gallery-brand-personalization"
                    subBrand={SubBrands.VistaPrint}
                    writeTenant={process.env.NODE_ENV === 'production' ? Tenants.VistaPrint : Tenants.VistaNonProd}
                    onAssetDeleted={handleAssetDelete}
                    onError={handleUploadError}
                    onUpload={handleUploadStart}
                    onUploadCompleted={handleUploadComplete}
                >
                    <LocalizationProvider
                        localizedValues={{
                            deletingLabel: localize('PhotoPersonalizationImageSelectionAssetDeletingLabel'),
                            deleteConfirmationKeys: {
                                closeButton: 'Close Delete Image Dialog',
                                dialogTitle: localize('PersonalizationModalDeleteImageConfirmationHeader'),
                                dialogMessage: localize('PersonalizationModalDeleteImageConfirmationDescription'),
                                cancelButton: localize('Cancel'),
                                confirmButton: localize('Confirm'),
                            },
                            deleteButtonTitle: localize('PhotoPersonalizationDeleteImageButton'),
                            assetInUseLabel: localize('PhotoPersonalizationImageUsedHoverText'),
                            loadingLabel: localize('PhotoPersonalizationImageSelectionAssetLoadingLabel'),
                            uploadingLabel: localize('PhotoPersonalizationImageSelectionAssetUploadingLabel'),
                        }}
                    >
                        <BrandProfileProvider
                            includeSpeculations
                            authorization={brandAuth}
                            requestor={config.appName}
                        >
                            {children}
                        </BrandProfileProvider>
                    </LocalizationProvider>
                </UploadManagerProvider>
            </UploadConfigurationProvider>
        </PersonalizationReactContext.Provider>
    );
};

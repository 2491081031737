import { WES_TENANT } from 'client/constants';
import { useSelector } from 'react-redux';
import {
    getLocaleSelector,
    getGalleryIdSelector,
    getMpvid,
    getMarket,
    getH1Title,
    getQuantitySelector,
} from 'client/store/config/reducer';
import {
    AnalyticsNames, ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_DETAILS, ANALYTICS_LABEL, PREVIEW_TYPE,
} from 'shared/constants';
import { useAuth } from 'client/hooks/useAuth';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { colorSwatchSelector } from 'client/store/design';
import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { createFavorite } from 'client/mutations/createFavorite';
import { deleteFavorite } from 'client/mutations/deleteFavorite';
import { QUERY_KEY } from 'client/queries/queryFavorites';
import { usePreviewDimensions } from 'client/hooks/usePreviewDimensions';
import { resolveInitialSwatchId } from 'shared/resolveInitialSwatchId';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ChangeEvent, useEffect, useState } from 'react';
import { getLogger } from 'client/utils/favorites/logger';
import { galleryImpressionIdSelector } from '~/client/store/analytics/reducer';
import { FavoriteButton } from 'client/components/common/FavoriteButton';

interface PropTypes {
    entityId: string;
    previewType: PREVIEW_TYPE;
    colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[];
    favoriteId?: string;
}

const getLocationFromPreviewType = (previewType: PREVIEW_TYPE): string => {
    switch (previewType) {
        case PREVIEW_TYPE.QUICKVIEW:
            return AnalyticsNames.Quickview;
        default:
            return AnalyticsNames.DesignTile;
    }
};

export const TileEntityFavorite = (props: PropTypes): JSX.Element => {
    const {
        entityId, previewType, colorSwatchObjects, favoriteId,
    } = props;
    const locale = useSelector(getLocaleSelector);
    const mpvId = useSelector(getMpvid);
    const galleryId = useSelector(getGalleryIdSelector);
    const market = useSelector(getMarket);
    const galleryH1Title = useSelector(getH1Title);
    const quantity = useSelector(getQuantitySelector);
    const selectColorSwatch = useSelector(colorSwatchSelector);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const auth = useAuth();
    const tileEntity = tileEntityById(entityId);
    const initialSwatchId = resolveInitialSwatchId(tileEntity);
    const queryClient = useQueryClient();
    const [isFavorite, setIsFavorite] = useState<boolean>(!!favoriteId);
    const location = getLocationFromPreviewType(previewType);
    const impressionId = useSelector((state: State.GlobalState) => galleryImpressionIdSelector(state, entityId));

    useEffect(() => {
        setIsFavorite(!!favoriteId);
    }, [favoriteId]);

    const createFavoriteMutation = useMutation(createFavorite, {
        onMutate: () => {
            setIsFavorite(true);
        },
        onError: () => {
            setIsFavorite(false);
        },
    });
    const deleteFavoriteMutation = useMutation(deleteFavorite, {
        onMutate: () => {
            setIsFavorite(false);
        },
        onError: () => {
            setIsFavorite(true);
        },
    });

    const defaultColorSwatch = selectColorSwatch(initialSwatchId, entityId, PREVIEW_TYPE.TILE, true);
    const previewHeight = usePreviewDimensions();
    const analytics = useAnalytics();

    const handleChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (!favoriteId) {
            const newFavorite: VP.DTT.Models.WorkEntityService.WorkEntity<Gallery.ContentQuery.PreviewInfo> = {
                workName: `${locale}_${galleryId}`,
                merchandising: {
                    merchandisingSelections: {
                        fullProductOptions: tileEntity.fullProductOptions,
                        productOptions: tileEntity.productOptions,
                    },
                    metadata: {
                        comparativeName: tileEntity.comparativeName || ``,
                        locale,
                        market,
                        mpvId,
                        galleryId,
                        galleryH1Title,
                    },
                    quantity: quantity || 0,
                },
                design: {
                    editUrl: defaultColorSwatch.studioUrl,
                    metadata: {
                        templateToken: tileEntity.previewInfo.templateToken,
                        designId: tileEntity.designId,
                        previewUrlSize1x: defaultColorSwatch.renderablePreviews.previewsUrls.size1x,
                        previewUrlSize2x: defaultColorSwatch.renderablePreviews.previewsUrls.size2x,
                        previewHeight,
                        previewInfo: tileEntity.previewInfo,
                    },
                },
                product: {
                    key: tileEntity.productKey,
                    version: tileEntity.productVersion,
                },
                isFavorite: true,
            };
            // optionally. Update wes query optimisitcally

            try {
                await createFavoriteMutation.mutateAsync({
                    tenant: WES_TENANT,
                    newFavorite,
                    ownerId: auth?.canonicalId,
                    accessToken: auth?.accessToken || undefined,
                });

                queryClient.cancelQueries([QUERY_KEY]);
                queryClient.invalidateQueries([QUERY_KEY]);

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
                    category: ANALYTICS_CATEGORY.ALL,
                    eventDetail: ANALYTICS_EVENT_DETAILS.FAVORITE_ADDED,
                    eventLabel: ANALYTICS_LABEL.FAVORITE_ADDED,
                    ...analytics.buildDesignEngagement({
                        engagementAction: ANALYTICS_EVENT_DETAILS.FAVORITE_ADDED,
                        selectedDesign: tileEntity.designId,
                        color: defaultColorSwatch.color,
                        tileEntity,
                        colorSwatchObjects,
                        location,
                        impressionId,
                        position: tileEntity.position,
                    }),
                });
            } catch (e) {
                getLogger().warning(
                    `Unable to add design to Favorites: ${newFavorite.design.metadata.designId}`,
                    e as Error,
                    { updateFavoriteHere: newFavorite },
                );
                throw (e);
            }
        } else {
            try {
                await deleteFavoriteMutation.mutateAsync({
                    favoriteId,
                    accessToken: auth?.accessToken || undefined,
                });

                // cancel and then invalidate (invalidate using the filter predicate) and refetch  wes query
                // see ingest code for examples
                queryClient.cancelQueries([QUERY_KEY]);
                queryClient.invalidateQueries([QUERY_KEY]);

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
                    category: ANALYTICS_CATEGORY.ALL,
                    eventDetail: ANALYTICS_EVENT_DETAILS.FAVORITE_REMOVED,
                    eventLabel: ANALYTICS_LABEL.FAVORITE_REMOVED,
                    ...analytics.buildDesignEngagement({
                        engagementAction: ANALYTICS_EVENT_DETAILS.FAVORITE_REMOVED,
                        selectedDesign: tileEntity.designId,
                        color: defaultColorSwatch.color,
                        tileEntity,
                        colorSwatchObjects,
                        impressionId,
                        location,
                        position: tileEntity.position,
                    }),
                });
            } catch (e) {
                getLogger().warning(
                    `Unable to remove design from Favorites: ${tileEntity.designId}`,
                    e as Error,
                    { updateFavoriteHere: favoriteId },
                );
                throw (e);
            }
        }
    };

    return (
        <FavoriteButton
            isFavorite={isFavorite}
            onChange={handleChange}
        />
    );
};

TileEntityFavorite.displayName = 'TileEntityFavorite';

export function getFormattedLocale(
    inputLocale: string,
): Util.LocaleData {
    const localeParts = inputLocale.split('-');
    const language = localeParts[0];
    const region = localeParts[1].toUpperCase();
    const locale = `${language}-${region}` as i18n.LocaleWithUpperRegion;

    return {
        language,
        locale,
        region,
    };
}

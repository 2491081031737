import classnames from 'classnames';
import { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { RenderProperty } from 'shared/renderProperties';
import { tileEntityAllIdsSelector } from 'client/store/tileEntity';
import { currentPageSelector, totalEntityCountSelector } from 'client/store/paging';
import { stringRenderPropertySelector } from 'client/store/config';
import { usePreviewDimensions } from 'client/hooks/usePreviewDimensions';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { FullBleedUploadTile } from 'src/client/components/Gallery/PreviewArea/FullBleedUploadTile/FullBleedUploadTile';
import { CareTile } from 'client/components/Gallery/PreviewArea/CareTile';
import { collectionRefinementSelector, shouldHideFbuTileSelector } from 'client/store/refinement/selectors';
import { getIsLoadMoreAvailable } from '~/client/store/config/selectors';
import { DesignSkeleton } from 'client/components/Gallery/PreviewArea/DesignSkeleton';
import { MERCH_MODULES_COLLECTION, MERCH_MODULES_TILE_SIZE, MERCH_MODULES_WRAPPER_CLASS_NAME } from '~/experiments/MerchModules/constants';
import { MerchModules } from '~/experiments/MerchModules';
import { merchModulesTileEntityAllIdsSelector } from '~/client/store/merchModulesTileEntities';
import { currentPageInfoSelector } from '~/client/store/paging/selectors';
import { useInView } from 'react-intersection-observer';
import { getRawExperiments } from '~/client/store/experimentation';
import { isMerchModulesEnabled } from '~/experiments/MerchModules/utils';
import { ErrorBoundary } from '@vp/swan';
import { getCareData } from '~/client/store/care';
import { DesignTilesList } from 'client/components/Gallery/PreviewArea/DesignTilesList';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import { DIFY_TILE_POSITION_VARIATIONS } from '~/experiments/DIFYTilePosition/constants';

export const PreviewListComponent = (): JSX.Element => {
    const isExperimentActive = useExperimentation();
    const resultCount = useSelector(totalEntityCountSelector);
    const tileEntityIds = useSelector(tileEntityAllIdsSelector);
    const merchModulesTileEntityIds = useSelector(merchModulesTileEntityAllIdsSelector);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const currentPage = useSelector(currentPageSelector);
    const shouldHideFbuTile = useSelector(shouldHideFbuTileSelector);
    const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable);
    const collectionRefinement = useSelector(collectionRefinementSelector);
    const { pageSize } = useSelector(currentPageInfoSelector);
    const rawExperiments = useSelector(getRawExperiments);
    const care = useSelector(getCareData);

    const DIFYTileVariant1Enabled = isExperimentActive(DIFY_TILE_POSITION_VARIATIONS.Enabled1);

    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize);
    const previewHeight = usePreviewDimensions();
    const previewListRef = useRef<HTMLUListElement>(null);

    const isFirstPage = currentPage === 1;
    const isFirstPageAndTemplatesExist = isFirstPage && resultCount > 0;
    const shouldShowFbuTile = isFirstPageAndTemplatesExist && !shouldHideFbuTile;
    const shouldShowCareTile = isFirstPageAndTemplatesExist && care?.designServicesAvailable;

    // Render Props
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);
    const { ref } = useInView({ threshold: 1 });

    const isMerchModulesRefinementActive = collectionRefinement?.value === MERCH_MODULES_COLLECTION;
    const shouldShowMerchModules = isMerchModulesEnabled(rawExperiments)
        && isFirstPage
        && !isMerchModulesRefinementActive
        && merchModulesTileEntityIds.length === MERCH_MODULES_TILE_SIZE;

    const careTilePosition = pageSize - 1;
    const isCareTileAtEnd = careTilePosition >= tileEntityIds.length;
    const shouldShowTileCareAtEnd = shouldShowCareTile && isCareTileAtEnd && !DIFYTileVariant1Enabled;

    useEffect(() => {
        if (shouldShowMerchModules) {
            const wrapper = previewListRef.current;

            if (!wrapper) {
                return;
            }

            const merchModules = wrapper.querySelector(`.${MERCH_MODULES_WRAPPER_CLASS_NAME}`);

            if (!merchModules) {
                return;
            }

            merchModules.classList.remove('hidden');

            const wrapperBottom = wrapper.getBoundingClientRect().bottom;
            const merchModulesBottom = merchModules.getBoundingClientRect().bottom;

            if (wrapperBottom === merchModulesBottom) {
                merchModules.classList.add('hidden');
            }
        }
    }, [tileEntityIds.length, shouldShowMerchModules]);

    return (
        <ul
            className={classnames(
                'preview-area',
                `preview-area-${tilePreviewSize?.toLowerCase()}`,
                'stylized-list',
                'stylized-list-minimal',
            )}
            ref={ref}
        >
            {shouldShowFbuTile && (
                <ErrorBoundary fallback={<>&nbsp;</>} forceShowError={false}>
                    <FullBleedUploadTile
                        key="fbu-tile"
                        previewHeight={previewHeight}
                        pricingPresentationType={pricingPresentationType}
                        productOptionsByKey={productOptionsByProductKey}
                        userInteractionLocation="tile"
                    />
                </ErrorBoundary>
            )}
            <DesignTilesList />
            {shouldShowTileCareAtEnd && <CareTile previewHeight={previewHeight} />}
            {isLoadMoreAvailable && <DesignSkeleton />}
            {shouldShowMerchModules && <MerchModules ref={ref} />}
        </ul>
    );
};

export const PreviewList = memo(PreviewListComponent);

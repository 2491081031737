import { useSelector } from 'react-redux';
import { usePreviewImageAlt } from '~/client/hooks/usePreviewImageAlt';
import { getH1Title, getLocaleSelector, getTlpLevel } from '~/client/store/config/reducer';
import { getExperienceType } from '~/client/store/debug/reducer';
import { getColorSwatchColors, getIsColorSwatchesSimilarColors } from '~/client/utils/getColorSwatchColors';
import { hash } from '~/client/utils/hash';
import { EXPERIENCE_TYPE } from '~/shared/constants';
import { useI18nColorName } from '~/client/hooks/useI18nColorName';

const getTemplateCategory = (id: string, categoryNamesArray: string[]): string => {
    const hashId = hash(id);
    const index = Number(hashId) % categoryNamesArray.length;

    return categoryNamesArray[index];
};

export const useNewTemplateAltText = (tileEntity: State.TileEntity, colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[]): string => {
    const defaultAltText = usePreviewImageAlt(tileEntity.comparativeName);
    const tlpLevel = useSelector(getTlpLevel);
    const experienceType = useSelector(getExperienceType);
    const h1Title = useSelector(getH1Title);
    const locale = useSelector(getLocaleSelector);
    const isDefaultLocale = locale === 'en-us';
    const isL0OrGalleryPage = tlpLevel === 'l0' || experienceType === EXPERIENCE_TYPE.GALLERY;
    const shouldUseNewAltText = isDefaultLocale && h1Title
        && tileEntity.categoryNames && tileEntity.categoryNames.length;

    const isColorSwatchColorsSimilar = getIsColorSwatchesSimilarColors(colorSwatchObjects);

    const {
        primaryColor,
        secondaryColor,
    } = getColorSwatchColors(tileEntity.colorComposition, isColorSwatchColorsSimilar);

    const primaryColorTitle = useI18nColorName(primaryColor, locale);
    const secondaryColorTitle = useI18nColorName(secondaryColor, locale);

    if (!shouldUseNewAltText) {
        return tileEntity.altText ?? defaultAltText;
    }

    const formattedH1Title = h1Title.toLocaleLowerCase();
    const formattedCategory = getTemplateCategory(tileEntity.designId, tileEntity.categoryNames).toLocaleLowerCase();

    if (!tileEntity.colorComposition.length) {
        return `A(n) ${formattedH1Title} for ${formattedCategory}`;
    }

    if (!secondaryColor) {
        return isL0OrGalleryPage
            ? `A(n) ${formattedH1Title} in ${primaryColorTitle} for ${formattedCategory}`
            : `A(n) ${formattedH1Title} in ${primaryColorTitle}`;
    }

    return isL0OrGalleryPage
        ? `A(n) ${formattedH1Title} in ${primaryColorTitle} and ${secondaryColorTitle} for ${formattedCategory}`
        : `A(n) ${formattedH1Title} in ${primaryColorTitle} and ${secondaryColorTitle}`;
};

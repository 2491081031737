import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { BasicCollapsible } from '@vp/swan';

import { FilterCheckboxList } from 'client/components/Gallery/Filters/Shared/FilterCheckboxList';
import { ShowMoreButton } from 'client/components/Gallery/Filters/Shared/ShowMoreButton';
import { categoryOptionsSelectorFactory } from 'client/components/Gallery/Filters/util';
import { REFINEMENT_DIMENSION } from '~/shared/constants';

export interface PropTypes {
    filter: State.Filter;
}

export const FlatTaxonomy = (props: PropTypes): JSX.Element | null => {
    const { filter } = props;

    const f = filter as State.CategoryFilter;
    const [showMore, setShowMore] = useState(false);
    const selectCategoryOptions = useMemo(categoryOptionsSelectorFactory, []);

    const {
        options,
        selectedValues,
        visible,
    } = useSelector((state: State.GlobalState) => selectCategoryOptions(state, f.categoryId.toString()));

    // if there's no bestselling count, set it to a really high number so everything is rendered
    const bestSellingCount = f.bestSellingCount || Number.MAX_SAFE_INTEGER;
    const shouldShowCategory = (o: RenderableCategoryFilterOption): boolean => (
        showMore || !o.rank || o.rank < bestSellingCount
    );

    return visible ? (
        <BasicCollapsible
            aria-label={f.title}
            className="filter"
            collapsibleId={filter.name}
            heading={f.title}
        >
            <FilterCheckboxList
                options={options}
                refinementDimension={REFINEMENT_DIMENSION.CATEGORY}
                selectedValues={selectedValues}
                shouldShowFilterOption={shouldShowCategory}
                title={f.title}
            />

            {(options.length > bestSellingCount) && (
            <ShowMoreButton
                showMore={showMore}
                title={filter.title}
                onClick={(): void => setShowMore(!showMore)}
            />
            )}
        </BasicCollapsible>
    ) : null;
};

FlatTaxonomy.displayName = 'FlatTaxonomy';

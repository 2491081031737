import { Spinner } from '@vp/swan';
import { useTranslations } from '~/client/hooks/useTranslations';

export const GalleryQuickViewSpinner = (): JSX.Element => {
    const localize = useTranslations();

    return (
        <div className="mcp-img-loading">
            <Spinner accessibleText={localize('Loading')} size="standard" />
        </div>
    );
};

import config from 'config';

import { AbstractService, AbstractServiceConfig, LoggerParameter } from 'services/AbstractService';
import { ServiceError } from 'services/errors';
import { getLogger } from 'client/utils/favorites/logger';

const DEFAULT_BOOSTS_DAYS_OF_HISTORY = 7;

class DesignPersonalizationContextServiceApi extends AbstractService implements VP.DesignPersonalization.Services.DesignPersonalizationContextService.IDesignPersonalizationContextService {
    apiVersion: number;

    constructor({ version, ...rest }: AbstractServiceConfig, logger: LoggerParameter) {
        super(rest, logger);
        this.apiVersion = version;
    }

    /**
     * Add interactions to DPCS
     * @param canonicalId
     * @param interactions
     */
    public async addTemplateInteractions(
        canonicalId: string,
        interactions: VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteraction[],
        accessToken?: string,
    ): Promise<string> {
        const url = `/${this.apiVersion}/templateInteractions:list`;

        if (canonicalId === undefined || accessToken === undefined) {
            throw new ServiceError({
                canonicalId,
                url,
                message: `Unable to call addTemplateInteractions, no canonicalId and/or accessToken provided`,
            });
        }

        try {
            const response = await this.api.post(
                url,
                { interactions },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        canonicalId,
                        from: config.appName,
                        requestor: config.appName,
                    },
                },
            );

            return response.data;
        } catch (e) {
            throw new ServiceError({
                interactions,
                url,
                message: `Bad response from DPCS: ${(e as Error).message}`,
            }, e as Error);
        }
    }

    /**
     * Gets all interactions from DPCS for a given parameters
     * @param canonicalId
     * @param daysOfHistory
     * @param accessToken
     */
    public async getTemplateInteractions(
        canonicalId?: string | null,
        accessToken?: string | null,
    ): Promise<VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteraction[]> {
        const url = `/${this.apiVersion}/templateInteractions`;

        if (!accessToken) {
            throw new ServiceError({
                url,
                message: `Unable to call getTemplateInteractions, no accessToken provided`,
            });
        }

        try {
            const response = await this.api.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        canonicalId,
                        daysOfHistory: DEFAULT_BOOSTS_DAYS_OF_HISTORY.toString(),
                        requestor: config.appName,
                    },
                },
            );

            return response.data;
        } catch (e) {
            // Log error but don't throw
            this.logger.warning({
                message: `Failed to get template interactions: ${(e as Error).message}`,
                url,
                canonicalId,
                error: e,
            });

            // Return empty array instead of throwing
            return [];
        }
    }

    /**
     * Gets summary of interactions from DPCS for a given parameters
     * @param canonicalId
     * @param interactionType
     * @param interactionSubtype
     * @param productKey
     * @param daysOfHistory
     * @param accessToken
     */
    public async getTemplateInteractionsSummary(
        canonicalId: string,
        interactionType?: string[],
        interactionSubtype?: string[],
        productKey?: string[],
        daysOfHistory?: number,
        accessToken?: string | null,
    ): Promise<VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteractionSummary[]> {
        const url = `/${this.apiVersion}/templateInteractions/summary`;

        if (!canonicalId || !accessToken) {
            throw new ServiceError({
                url,
                message: `Unable to call getTemplateInteractionsSummary, no canonicalId and/or accessToken provided`,
            });
        }

        try {
            const response = await this.api.get(
                url,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    params: {
                        canonicalId,
                        daysOfHistory,
                        requestor: config.appName,
                        interactionSubtype,
                        interactionType,
                        productKey,
                    },
                },
            );

            return response.data;
        } catch (e) {
            throw new ServiceError({
                url,
                message: `Bad response from DPCS: ${(e as Error).message}`,
            }, e as Error);
        }
    }
}

export const DesignPersonalizationContextService = new DesignPersonalizationContextServiceApi(config.services.designPersonalizationContextService, getLogger);

import { useEffect, useState } from 'react';
import { matchGalleryParams } from 'client/utils/matchGalleryParams';

// Since we have two types of routing on a Gallery itself an potentially unlimited types on TLP,
// we can't use `useParams` provided by react-router. The current hook returns base client URL regardless of page type.
export const useGalleryBaseURL = (): string => {
    const [baseURL, setBaseURL] = useState<string>('');

    useEffect(() => {
        const { segment } = matchGalleryParams(window.location.pathname);

        setBaseURL((segment ?? []).join('/'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return baseURL;
};

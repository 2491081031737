import { useUploadManager } from '@design-stack-vista/upload-components';
import {
    FlexBox, Icon, IconProps, Typography,
} from '@vp/swan';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';

type PersonalizationAppliedImageIconProps = Omit<IconProps, 'src' | 'classname'>

export const PersonalizationAppliedImageIcon = (props: PersonalizationAppliedImageIconProps): JSX.Element => {
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const firstUpload = selectedUploads[0];
    const { assetStore } = useUploadManager();
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    useEffect(
        () => {
            const getThumbnailUrl = async (): Promise<void> => {
                const asset = await assetStore?.fetchSingleAsset({ id: firstUpload.assetId });

                if (!asset) {
                    return;
                }

                await asset.presign();

                setThumbnailUrl(asset.thumbnail.getUrl({ includeSignature: true, pageNum: firstUpload.page ?? 1 }));
            };

            getThumbnailUrl();
        },
        [assetStore, firstUpload],
    );

    return (
        <FlexBox>
            <Icon className="personalization-change-image-button-icon" src={thumbnailUrl} {...props} backgroundColor="transparent" />
            {selectedUploads.length > 1 && (
                <FlexBox alignItems="center" className="personalization-extra-images-icon" justifyContent="center">
                    <Typography fontSize="small">
                        {`+${selectedUploads.length - 1}`}
                    </Typography>
                </FlexBox>
            )}
        </FlexBox>
    );
};

import { dpcToAppliedUploads } from '~/client/components/Gallery/Filters/PersonalizationFilter/dpcConverters';

export const designPersonalizationContextSelector = (
    state: State.GlobalState,
): Gallery.Models.Personalization.DesignPersonalizationContext => state.personalization.designPersonalizationContext;

export const appliedUploadsSelector = (
    state: State.GlobalState,
): Gallery.Models.Personalization.UploadIdentifier[] => dpcToAppliedUploads(
    state.personalization.designPersonalizationContext,
);

export const logoAppliedSelector = (
    state: State.GlobalState,
): boolean => (state.personalization.designPersonalizationContext?.images
    && (state.personalization.designPersonalizationContext.images.length > 0)) ?? false;

import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { useMemo } from 'react';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES } from 'client/constants';
import {
    ActionInfo,
} from 'client/components/Gallery/Header/Personalization/utils';
import {
    ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_DETAILS, ANALYTICS_LABEL,
} from 'shared/constants';

const PERSONALIZATION_CATEGORY_SUFFIX = 'PP';

export type PersonalizationAnalytics = {
    recordPreviewButtonClicked: () => void;
    recordUploadButtonClicked: () => void;
    recordPrivacyPolicyClicked: () => void;
    recordFlyoutClosed: () => void;
    recordPersonalizationSignIn: () => void;
    recordApplyPhotosClicked: (numPhotos: number) => void;
    recordImageUploadSuccess: (actionInfo: ActionInfo) => void;
    recordClearPhotoButtonClicked: () => void;
    recordImageUploadError: (actionInfo: ActionInfo, errorMessage: string) => void;
    recordImageProcessingSuccess: (actionInfo: ActionInfo) => void;
    recordImageProcessingError: (actionInfo: ActionInfo) => void;
    recordImageLoadingSuccess: (actionInfo: ActionInfo) => void;
    recordImageLoadingError: (actionInfo: ActionInfo) => void;
};

const buildPersonalizationAnalytics = (
    analytics: Gallery.Analytics.IAnalytics,
    pageProperties: Gallery.Analytics.PageProperties,
): PersonalizationAnalytics => ({
    recordPreviewButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PREVIEW_WITH_PHOTO,
            ...pageProperties,
        });
    },
    recordUploadButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.UPLOAD_PHOTO,
            ...pageProperties,
        });
    },
    recordPrivacyPolicyClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PRIVACY_CLICKED,
            ...pageProperties,
        });
    },
    recordFlyoutClosed: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.PREVIEW_WITH_PHOTO,
            ...pageProperties,
        });
    },
    recordImageUploadSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_UPLOAD_SUCCESS,
            actionInfo,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_SUCCESS,
            ...actionInfo,
            ...pageProperties,
        });
    },
    recordClearPhotoButtonClicked: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_CLEAR,
            ...pageProperties,
        });
    },
    recordImageUploadError: (actionInfo: ActionInfo, errorMessage: string): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_UPLOAD_FAILURE,
            { ...actionInfo, reason: errorMessage },
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            category: ANALYTICS_CATEGORY.GALLERY,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_ERROR,
            eventDetail: errorMessage,
            ...actionInfo,
            ...pageProperties,
        });
    },
    recordImageProcessingSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_PROCESSING_SUCCESS,
            actionInfo,
        );
    },
    recordImageProcessingError: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_PROCESSING_FAILURE,
            actionInfo,
        );
    },
    recordApplyPhotosClicked: (numPhotos: number): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: `${ANALYTICS_CATEGORY.FLY_OUT}_${PERSONALIZATION_CATEGORY_SUFFIX}`,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_FLY_OUT,
            eventDetail: ANALYTICS_EVENT_DETAILS.APPLY_PHOTO,
            numPhotosApplied: numPhotos,
            ...pageProperties,
        });
    },
    recordPersonalizationSignIn: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            category: `${ANALYTICS_CATEGORY.FLY_OUT}_${PERSONALIZATION_CATEGORY_SUFFIX}`,
            eventLabel: ANALYTICS_LABEL.PHOTO_PREVIEW_SIGN_IN,
            ...pageProperties,
        });
    },
    recordImageLoadingSuccess: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_LOADING_SUCCESS,
            actionInfo,
        );
    },
    recordImageLoadingError: (actionInfo: ActionInfo): void => {
        instrumenter.recordPageAction(
            PAGE_ACTION_TYPES.IMAGE_LOADING_FAILURE,
            actionInfo,
        );
    },

});

export const usePersonalizationAnalytics = (): PersonalizationAnalytics => {
    const analytics = useAnalytics();
    const pageProperties = analytics.getPageProperties();

    const personalizationAnalytics = useMemo(
        () => buildPersonalizationAnalytics(analytics, pageProperties),
        [analytics, pageProperties],
    );

    const memo = useMemo(
        () => (personalizationAnalytics),
        [personalizationAnalytics],
    );

    return memo;
};

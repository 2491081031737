import { AnyAction } from 'redux';

const initialState: State.IgpCategoriesState = [];

export function reducer(
    state: State.IgpCategoriesState = initialState,
    action: AnyAction,
): State.IgpCategoriesState {
    switch (action.type) {
        default:
            return state;
    }
}

import {
    COLOR_SWATCH_DIFFERENCE_THRESHOLD,
    PRIMARY_COLOR_SWATCH_INDEX,
    SECONDARY_COLOR_SWATCH_INDEX,
    SIMILARITY_COLOR_SWATCH_RATIO,
} from '~/client/constants';

interface GetColorSwatchColorsResult {
    primaryColor: string;
    secondaryColor?: string;
}

const rgbToHex = (hex: string): Gallery.ContentQuery.RGB => {
    const color = hex.replace(/^#/, '');

    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);

    return { r, g, b };
};

const colorDelta = (color1: string, color2: string): number => {
    const rgb1 = rgbToHex(color1);
    const rgb2 = rgbToHex(color2);

    return Math.sqrt(
        (rgb1.r - rgb2.r) ** 2
      + (rgb1.g - rgb2.g) ** 2
      + (rgb1.b - rgb2.b) ** 2,
    );
};

export const compareColorSwatchColors = (colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[], index: number): boolean => {
    const colorsSwatchColorByIndex = colorSwatchObjects.map(
        (colorSwatch) => colorSwatch?.colorComposition?.[index]?.color,
    );

    let similarCount = 0;
    const threshold = Math.floor(colorsSwatchColorByIndex.length * SIMILARITY_COLOR_SWATCH_RATIO);

    colorsSwatchColorByIndex.forEach((color, i) => {
        if (i < colorsSwatchColorByIndex.length - 1) {
            const color1 = color;
            const color2 = colorsSwatchColorByIndex[i + 1];

            if (color1 && color2) {
                const delta = colorDelta(color1, color2);

                if (delta < COLOR_SWATCH_DIFFERENCE_THRESHOLD) {
                    similarCount += 1;
                }
            }
        }
    });

    return similarCount >= threshold;
};

export const getIsColorSwatchesSimilarColors = (colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[]): boolean => {
    const primaryColorSwatchResult = compareColorSwatchColors(
        colorSwatchObjects,
        PRIMARY_COLOR_SWATCH_INDEX,
    );
    const secondaryColorSwatchResult = compareColorSwatchColors(
        colorSwatchObjects,
        SECONDARY_COLOR_SWATCH_INDEX,
    );

    return primaryColorSwatchResult && secondaryColorSwatchResult;
};

export const getColorSwatchColors = (
    colorComposition: Gallery.ContentQuery.ColorComposition[],
    isColorSwatchColorsSimilar?: boolean,
): GetColorSwatchColorsResult => {
    const [primaryColor, secondaryColor, thirdColor] = colorComposition.map(({ color }) => color);

    const changedSecondaryColor = isColorSwatchColorsSimilar ? thirdColor : secondaryColor;

    return {
        primaryColor,
        secondaryColor: changedSecondaryColor,
    };
};

import { AuthDataAction, SET_AUTH_DATA } from './actions';

const initialState: State.AuthDataState = {};

export function reducer(
    state: State.AuthDataState = initialState,
    action: AuthDataAction,
): State.AuthDataState {
    switch (action.type) {
        case SET_AUTH_DATA:
            return action.payload as State.AuthDataState;
        default:
            return state;
    }
}

export const authDataSelector = (state: State.GlobalState): State.AuthDataState => state.authData;

import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { Box } from '@vp/swan';
import { ShippingCalculator, Locale, RequestorId } from '@vp/shipping-calc-components';
import config from 'config';

import { getFormattedLocale } from 'shared/localeUtils';
import { getProductKey, getProductVersion } from 'client/store/config';
import { pricingContextAtom } from 'client/atoms/pricingContext';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_CATEGORY } from 'shared/constants';
import { getTenantId } from 'client/utils/getTenantId';

export type ShippingInfoPropTypes = {
    locale: i18n.Locale;
    quantity: number;
    selectedProductOptions: Gallery.ContentQuery.ProductOptions;
};

export const ShippingInfo = memo(({ locale, quantity, selectedProductOptions }: ShippingInfoPropTypes): JSX.Element => {
    const analytics = useAnalytics();
    const productKey = useSelector(getProductKey);
    const productVersion = useSelector(getProductVersion);

    const pricingContext = useRecoilValue(pricingContextAtom);
    const retrievedPricingContext = pricingContext?.getPricingContext();

    const { locale: formattedLocale, region } = getFormattedLocale(locale);
    const vatInclusivity = retrievedPricingContext?.vatInclusive || false;
    const tenantId = getTenantId(config.tenant, region);

    return (
        <Box className="shipping-info">
            <ShippingCalculator
                clientConfiguration={{
                    requestor: config.appName,
                    requestorId: RequestorId.Gallery6Client,
                }}
                productConfiguration={{
                    productKey,
                    productSelections: selectedProductOptions,
                    productQuantity: quantity,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    productVersion: productVersion!,
                }}
                tenantConfiguration={{
                    locale: formattedLocale as Locale,
                    tenant: config.tenant,
                    tenantId,
                }}
                trackingData={{
                    ...analytics.getPageProperties(),
                    category: ANALYTICS_CATEGORY.GALLERY,
                }}
                userConfiguration={{
                    vatInclusivity,
                }}
            />
        </Box>
    );
});

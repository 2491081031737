import { useState } from 'react';
import {
    List,
    ListItem,
    Button,
} from '@vp/swan';
import { useDispatch } from 'react-redux';
import { ShowMoreButton } from 'client/components/Gallery/Filters/Shared/ShowMoreButton';
import { FilterOption } from 'client/components/Gallery/Filters/FilterOption';
import { buildRefinement, refinementAdd } from 'client/store/refinement';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export interface L1FilterProps {
    filter: State.Filter;
    options: RenderableFilterOption[];
    refinementDimension: REFINEMENT_DIMENSION;
}

export const L1Filter = (props: L1FilterProps): JSX.Element => {
    const { options, filter, refinementDimension } = props;
    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);
    const bestSellingCount = (refinementDimension === REFINEMENT_DIMENSION.CATEGORY)
        ? (filter as State.CategoryFilter).bestSellingCount || Number.MAX_SAFE_INTEGER
        : Number.MAX_SAFE_INTEGER;
    const availableOptions = options.filter((o) => showMore || !o.rank || o.rank < bestSellingCount);

    const redirectToL0 = useRedirectToL0();

    const curryHandleL1Selection = (id: string) => (
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.preventDefault();

            const refinement = buildRefinement(id, refinementDimension);

            if (redirectToL0([refinement], [])) {
                return;
            }

            dispatch(refinementAdd([refinement]));
            if (isFilterModalHidden()) {
                scrollUpToElement(galleryHeaderId);
            }
        }
    );

    return (
        <>
            <List aria-label={filter.title} skin="minimal">
                {availableOptions.map((o) => (
                    <ListItem key={o.value}>
                        <Button
                            disabled={o.disabled}
                            skin="unstyled"
                            onClick={curryHandleL1Selection(o.value)}
                        >
                            <FilterOption facetCount={o.facetCount} title={o.title} />
                        </Button>
                    </ListItem>
                ))}
            </List>

            {options.length > bestSellingCount && (
                <ShowMoreButton showMore={showMore} title={filter.title} onClick={(): void => setShowMore(!showMore)} />
            )}
        </>
    );
};

L1Filter.displayName = 'L1Filter';

import { createContext, Dispatch, SetStateAction } from 'react';

export interface PersonalizationReactContextInterface {
    experimentName?: string;
    experimentVariation?: string;
    textfieldValues: Record<string, string>;
    setTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
    isSmallScreenPersonalizationEnabled: boolean
    mobileTextfieldValues: Record<string, string>;
    setMobileTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
}

export const PersonalizationReactContext = createContext<PersonalizationReactContextInterface>({
    textfieldValues: {},
    setTextfieldValues: null,
    isSmallScreenPersonalizationEnabled: false,
    mobileTextfieldValues: {},
    setMobileTextfieldValues: null,
});

import { useContext, useMemo } from 'react';
import { useAsyncEffect } from '@design-stack-ct/utility-react';
import { useUploadManager } from '@design-stack-vista/upload-components';
import { IMAGE_UPLOAD_STATES } from 'src/client/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { shouldRestorePhotosState } from 'src/client/atoms/shouldRestorePhotosAtom';
import { designPersonalizationContextUpdate } from 'client/store/personalization/actions';
import { designPersonalizationContextSelector } from '~/client/store/personalization/selectors';
import { contentUpdate } from '~/client/store/content';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { buildDesignPersonalizationContext } from '~/client/store/personalization/util';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { usePersistedDpcKey } from './usePersistedDpcKey';
import { usePurposeNames } from './usePurposeNames';
import { dpcToAppliedUploads, dpcToTextFields } from '../../../Filters/PersonalizationFilter/dpcConverters';

function getAssetIdsFromDpc(dpc: Gallery.Models.Personalization.DesignPersonalizationContext): string[] {
    return dpc?.images?.map((i) => i.image.assetId) ?? [];
}

export const useRestoreDpc = (): void => {
    const setUploadState = useSetRecoilState(imageUploadState);
    const setSelectedUploads = useSetRecoilState(selectedUploadsState);
    const [shouldRestorePhotos, setShouldRestorePhotos] = useRecoilState(shouldRestorePhotosState);
    const purposeNames = usePurposeNames();
    const {
        setTextfieldValues,
        setMobileTextfieldValues,
    } = useContext(PersonalizationReactContext);
    const analytics = useAnalytics();
    // Used for removing dpc data
    const localStorageKey = usePersistedDpcKey();

    const { assetStore } = useUploadManager();

    const dispatch = useDispatch();
    const dpc = useSelector(designPersonalizationContextSelector);

    const fetchAndPresignAsset = useMemo(() => async (
        id: string,
    ): Promise<void> => {
        if (!assetStore) {
            return;
        }
        const asset = await assetStore.fetchSingleAsset({ id });

        await asset.presign();
    }, [assetStore]);

    useAsyncEffect(({ runIfMounted }) => {
        const restoreDpc = async (): Promise<void> => {
            try {
                setUploadState({ status: IMAGE_UPLOAD_STATES.LOADING });

                const storedAssetIds = getAssetIdsFromDpc(dpc);

                if (storedAssetIds.length > 0) {
                    // Fetch the assets for the restored images
                    await Promise.all(storedAssetIds.map(fetchAndPresignAsset));

                    analytics.trackEvent({
                        action: ANALYTICS_EVENT_ACTIONS.PRODUCT_VIEWED,
                        eventLabel: 'Images repopulated from DPC',
                        eventDetail: 'Images repopulated from DPC',
                        ...analytics.getPageProperties(),
                    });

                    dispatch(contentUpdate());
                    setSelectedUploads(dpcToAppliedUploads(dpc));
                }

                const dpcTextFields = dpcToTextFields(purposeNames, dpc);

                if (Object.keys(dpcTextFields).length > 0) {
                    setTextfieldValues?.(dpcTextFields);
                    analytics.trackEvent({
                        action: ANALYTICS_EVENT_ACTIONS.PRODUCT_VIEWED,
                        eventLabel: 'Text fields repopulated from DPC',
                        eventDetail: 'Text fields repopulated from DPC',
                        ...analytics.getPageProperties(),
                    });
                }
                // if there was an error getting the uploads from localStorage, don't give the user access to dpc data
            } catch {
                dispatch(designPersonalizationContextUpdate(buildDesignPersonalizationContext({})));
                setSelectedUploads([]);
                setTextfieldValues?.({});
                setMobileTextfieldValues?.({});
                localStorage.removeItem(localStorageKey);
            } finally {
                setUploadState({ status: IMAGE_UPLOAD_STATES.READY });
                setShouldRestorePhotos(false);
            }
        };

        if (shouldRestorePhotos) {
            runIfMounted(restoreDpc);
        }
    }, [
        setUploadState,
        fetchAndPresignAsset,
        shouldRestorePhotos,
        setSelectedUploads,
        setShouldRestorePhotos,
    ]);
};

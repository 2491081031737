import { MIN_RELEVANT_BOOST_COUNT } from '../constants';

// Normalize the counts of the results to be between 0 and 1. 1 is equal to the max count of the results.
// Exclude results where the count is 1 or less. Split into keywords and categories.
export const normalizeTemplateInteractionsToBoosts = (templateInteractions: VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteraction[]): State.BoostsState => {
    let maxCount = 0;
    const boostedKeywords: Gallery.ContentQuery.Boost[] = [];
    const boostedCategories: Gallery.ContentQuery.Boost[] = [];

    const groupedTemplateInteractions = templateInteractions.reduce((acc, interaction) => {
        const key = `${interaction.subtype}-${interaction.data}`;

        acc[key] = (acc[key] || 0) + interaction.count;
        maxCount = Math.max(maxCount, acc[key]);
        return acc;
    }, {} as Record<string, number>);

    for (const [key, count] of Object.entries(groupedTemplateInteractions)) {
        const [subtype, value] = key.split('-');

        if (count >= MIN_RELEVANT_BOOST_COUNT) {
            if (subtype === 'keyword') {
                boostedKeywords.push({
                    value,
                    weight: count / maxCount,
                });
            } else if (subtype === 'category') {
                boostedCategories.push({
                    value,
                    weight: count / maxCount,
                });
            }
        }
    }

    return {
        boostedKeywords,
        boostedCategories,
    };
};

import classnames from 'classnames';
import { ImgHTMLAttributes, memo } from 'react';

import { resolveSrc, resolveSrcSet } from 'client/components/common/McpImage/utils';

export interface EagerLoadingImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>, Partial<Gallery.Designs.RenderablePreviewInfo> {
    // If set, override src and srcSet, ignoring any values passed in.
    srcInfo?: Gallery.Designs.McpImageSrcInfo;
    srcSet?: string;
    maxPreviewHeight: [number, string | false];
}

export const EagerLoadingImage = memo((props: EagerLoadingImageProps): JSX.Element => {
    const {
        alt,
        aspectRatio,
        className,
        maxPreviewHeight,
        srcInfo,
        srcSet,
        width,
        ...rest
    } = props;

    const currentSrcSet = resolveSrcSet(srcInfo, srcSet);
    const currentSrc = resolveSrc(srcInfo?.size2x);

    const height = maxPreviewHeight[1];

    return (
        <img
            {...rest}
            alt={alt}
            className={classnames('mcp-eager-image', className)}
            crossOrigin="anonymous"
            height={height ? height as string : undefined}
            src={currentSrc}
            srcSet={currentSrcSet}
            width={width}
        />
    );
});

EagerLoadingImage.displayName = 'EagerLoadingImage';

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBooleanFromValue } from 'shared/url';
import { personalizationFlyoutOpenState } from '~/client/atoms/personalizationFlyoutOpenAtom';
import { mobilePersonalizationOpenState } from 'src/client/atoms/mobilePersonalizationOpenAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';

const PERSONALIZATION_QSP = 'personalizationFlyoutOpen';

export type UsePersonalizationFlyoutOpen = [
    personalizationFlyoutOpen: boolean,
    setPersonalizationFlyoutOpenWithEffect: (isOpen: boolean) => void,
]

export const usePersonalizationFlyoutOpen = (): UsePersonalizationFlyoutOpen => {
    const [personalizationFlyoutOpen, setPersonalizationFlyoutOpen] = useRecoilState(personalizationFlyoutOpenState);
    const setPersonalizationModalOpen = useSetRecoilState(mobilePersonalizationOpenState);
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        const openFlyoutOnMount = getBooleanFromValue(
            new URLSearchParams(window.location.search).get(PERSONALIZATION_QSP),
        );

        // mobile needs the personalization modal open in order for child image upload modal to work
        if (openFlyoutOnMount) {
            setPersonalizationModalOpen(!!openFlyoutOnMount);
        }
        setPersonalizationFlyoutOpen(!!openFlyoutOnMount);
    }, [setPersonalizationFlyoutOpen, setPersonalizationModalOpen]);

    const setPersonalizationFlyoutOpenWithEffect = (isOpen: boolean): void => {
        setSearchParams((params) => {
            if (isOpen) {
                params.set(PERSONALIZATION_QSP, isOpen.toString());
            } else {
                params.delete(PERSONALIZATION_QSP);
            }

            return params;
        });
        setPersonalizationFlyoutOpen(isOpen);
    };

    return [personalizationFlyoutOpen, setPersonalizationFlyoutOpenWithEffect];
};

import { Button, Icon, Typography } from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { useSetRecoilState } from 'recoil';
import { filtersFlyoutOpenState, searchAutoFocusedState } from 'client/atoms/filtersFlyout';

interface SearchButtonProps {
    shouldHideText?: boolean;
}

export const SearchButton = (props: SearchButtonProps): JSX.Element => {
    const { shouldHideText } = props;
    const localize = useTranslations();
    // Header Hierarchry Test: Boths recoil states are needed to allow the mobile search button to open
    // the filters flyout and focus the search input.
    const setFiltersFlyoutOpen = useSetRecoilState(filtersFlyoutOpenState);
    const setSearchAutoFocused = useSetRecoilState(searchAutoFocusedState);

    const handleTransitionEnd = (): void => {
        setSearchAutoFocused(true);
        setFiltersFlyoutOpen(true);
    };

    return (
        <Button
            className="search-button"
            iconPosition="right"
            title={localize('HeaderHierarchySearchDesigns')}
            width={shouldHideText ? 'standard' : 'full-width'}
            onClick={handleTransitionEnd}
        >
            {!shouldHideText && <Typography as="span" fontSize="standard" fontWeight="normal">{localize('HeaderHierarchySearchDesigns')}</Typography>}
            <Icon iconType="search" />
        </Button>
    );
};

SearchButton.displayName = 'SearchButton';

import { atom } from 'recoil';
import { IMAGE_UPLOAD_STATES } from 'src/client/constants';

type RawUploadState = {
    status: IMAGE_UPLOAD_STATES;
    message?: string | null;
};

type UploadState = Omit<RawUploadState, IMAGE_UPLOAD_STATES.ERROR> | {
    status: IMAGE_UPLOAD_STATES.ERROR,
    message: string,
};

export const imageUploadState = atom<UploadState>({
    key: 'imageUploadState',
    default: {
        status: IMAGE_UPLOAD_STATES.READY,
        message: null,
    },
});

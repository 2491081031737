import { Fragment, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { tileEntityAllIdsSelector } from 'client/store/tileEntity';
import { currentPageSelector, totalEntityCountSelector } from 'client/store/paging';
import { usePreviewDimensions } from 'client/hooks/usePreviewDimensions';
import { CareTile } from 'client/components/Gallery/PreviewArea/CareTile';
import { currentPageInfoSelector, loadMoreCountSelector } from '~/client/store/paging/selectors';
import { getCareData } from '~/client/store/care';
import { DesignTileWrapper } from 'client/components/Gallery/PreviewArea/DesignTileWrapper';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import { DIFY_TILE_POSITION_VARIATIONS, DIFY_TILE_POSITIONS } from '~/experiments/DIFYTilePosition/constants';
import { getIsLoadMoreAvailable } from '~/client/store/config/selectors';
import { useColorSwatchResizeObserve } from '~/client/hooks/useColorSwatchResizeObserve';

const DesignTilesListComponent = (): JSX.Element => {
    const isExperimentActive = useExperimentation();
    const resultCount = useSelector(totalEntityCountSelector);
    const tileEntityIds = useSelector(tileEntityAllIdsSelector);
    const previewHeight = usePreviewDimensions();
    const currentPage = useSelector(currentPageSelector);
    const { pageSize } = useSelector(currentPageInfoSelector);
    const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable);
    const loadMoreCount = useSelector(loadMoreCountSelector);
    const care = useSelector(getCareData);
    const gridRef = useRef<HTMLDivElement>(null);
    const maxColorSwatchesPerRow = useColorSwatchResizeObserve(gridRef);

    const DIFYTileVariant1Enabled = isExperimentActive(DIFY_TILE_POSITION_VARIATIONS.Enabled1);

    const isFirstPage = currentPage === 1;

    const isFirstPageAndTemplatesExist = isFirstPage && resultCount > 0;
    const shouldShowCareTile = isFirstPageAndTemplatesExist && care?.designServicesAvailable;

    const careTilePosition = pageSize - 1;
    const isCareTileAtEnd = careTilePosition >= tileEntityIds.length;

    return (
        <>
            {tileEntityIds.map((entityId: string, index: number) => {
                const shouldPinCareTile = shouldShowCareTile && index === careTilePosition && !isCareTileAtEnd
                    && !DIFYTileVariant1Enabled;
                const shouldShowTileCareExperiment1 = shouldShowCareTile
                    && DIFYTileVariant1Enabled
                    && (
                        !isLoadMoreAvailable && (
                            DIFY_TILE_POSITIONS.indexOf(index) > -1
                            && DIFY_TILE_POSITIONS.indexOf(index) < loadMoreCount
                            || tileEntityIds.length === index + 1
                        )
                        || isLoadMoreAvailable && DIFY_TILE_POSITIONS.includes(index)
                    );
                const refValue = index === 0 ? gridRef : null;

                return (
                    <Fragment key={entityId}>
                        <DesignTileWrapper
                            entityId={entityId}
                            maxColorSwatchesPerRow={maxColorSwatchesPerRow}
                            refValue={refValue}
                        />
                        {(shouldPinCareTile || shouldShowTileCareExperiment1)
                            && <CareTile previewHeight={previewHeight} />}
                    </Fragment>
                );
            })}
        </>
    );
};

export const DesignTilesList = memo(DesignTilesListComponent);

import { AnyAction } from 'redux';
import {
    DESIGN_PERSONALIZATION_UPDATE, PERSONALIZED_TEXT_UPDATE,
    DESIGN_PERSONALIZATION_IMAGES_UPDATE,
} from './reducer';

export const personalizedTextUpdate = (text: Gallery.Models.Personalization.PersonalizedTextData, galleryId?: number): AnyAction => ({
    type: PERSONALIZED_TEXT_UPDATE,
    payload: { text, galleryId },
});

export const personalizedImageUpdate = (images: Gallery.Models.Personalization.DpcImage[] | undefined, galleryId?: number): AnyAction => ({
    type: DESIGN_PERSONALIZATION_IMAGES_UPDATE,
    payload: { images, galleryId },
});

export const designPersonalizationContextUpdate = (dpc: Gallery.Models.Personalization.DesignPersonalizationContext): AnyAction => ({
    type: DESIGN_PERSONALIZATION_UPDATE,
    payload: { dpc },
});

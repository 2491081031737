import { ComponentProps } from 'react';
import { useDispatch } from 'react-redux';
import {
    BasicCollapsible,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';

import { useFilterOptions } from 'client/components/Gallery/Filters/SingleSelect/useFilterOptions';
import {
    buildRefinement,
    refinementAddAndRemove,
} from 'client/store/refinement';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

interface PropTypes {
    children: (o: RenderableFilterOption) => JSX.Element;
    CollapsibleProps?: ComponentProps<typeof BasicCollapsible>;
    filter: State.AttributeFilter;
    SelectionSetProps?: Omit<ComponentProps<typeof SelectionSet>, 'defaultSelectedValue' | 'variant' | 'selectedValue' | 'selectedValues' | 'onSelectedValueChange' | 'onSelectedValuesChange' | 'skin'> & {
        variant: 'single-select';
    };
}

export const SingleSelectFilter = (props: PropTypes): JSX.Element => {
    const {
        children,
        CollapsibleProps = {},
        filter,
        SelectionSetProps = {},
    } = props;
    const { options, selected, visible } = useFilterOptions(filter);
    const dispatch = useDispatch();

    const redirectToL0 = useRedirectToL0();

    const handleSelectedValueChange = (newSelectedValue: string | number | null): void => {
        // shouldn't happen, but let's not update a radio button with an invalid value
        if (!newSelectedValue) {
            return;
        }

        const value = newSelectedValue ? newSelectedValue.toString() : '';
        const refinement = buildRefinement(value, REFINEMENT_DIMENSION.ATTRIBUTE);
        const oldRefinement = buildRefinement(selected, REFINEMENT_DIMENSION.ATTRIBUTE);

        if (redirectToL0([refinement], [oldRefinement])) {
            return;
        }

        dispatch(refinementAddAndRemove([refinement], [oldRefinement]));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <>
            {(!visible || options.length === 1) && null}
            {(visible && options.length > 1) && (
                <BasicCollapsible
                    aria-label={filter.title}
                    className="filter"
                    collapsibleId={filter.name}
                    heading={filter.title}
                    {...CollapsibleProps}
                >
                    <SelectionSet
                        aria-label={filter.title}
                        className="selection-set-layout-override"
                        selectedValue={selected}
                        skin="simple-column"
                        variant="single-select"
                        onSelectedValueChange={handleSelectedValueChange}
                        {...SelectionSetProps}
                    >
                        {options.map((o) => (
                            <div className="selection-set-item" key={o.value}>
                                <SelectionSetInput
                                    disabled={o.disabled}
                                    value={o.value}
                                >
                                    <SelectionSetLabel>
                                        {children(o)}
                                    </SelectionSetLabel>
                                </SelectionSetInput>
                            </div>
                        ))}
                    </SelectionSet>
                </BasicCollapsible>
            )}
        </>
    );
};

SingleSelectFilter.displayName = 'SingleSelectFilter';

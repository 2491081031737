import { BrandContent, BrandData } from '@vp/react-brand';
import { VistaAsset, VistaAssetStore } from '@design-stack-vista/vista-assets-sdk';
import { PURPOSE_NAMES } from './constants';
import { TemplateField } from './purposeHelpers';
import { splitFullName } from './splitFullName';
import { waitForDimensionData } from '../../Header/Personalization/utils';

const getTextFieldValuebyPurpose = (
    dpcText: Gallery.Models.Personalization.DpcText[] | undefined,
    purposeName: PURPOSE_NAMES,
): (string | undefined

    ) => dpcText?.find((t) => t.textField.purposeName === purposeName)?.textField.text;

export const dpcToBrandContent = (dpc: Gallery.Models.Personalization.DesignPersonalizationContext): BrandContent => {
    const brandContent: BrandContent = {};
    // For now, treat everything as facts.
    const facts: BrandData = {};

    const logos = dpc?.images?.filter((i) => i.purpose === 'logo');

    if (logos?.length) {
        facts.designIdentity = {
            ...facts?.designIdentity,
            logos: logos.map(({ image: { printUrl, previewUrl } }) => ({
                uds: {
                    printUrl,
                    previewUrl,
                },
            })),
        };
    }

    const companyName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.COMPANY_NAME);

    if (companyName) {
        facts.profile = {
            ...facts?.profile,
            companyName,
        };

        brandContent.displayName = companyName;
    }

    const fullName = getTextFieldValuebyPurpose(dpc?.text, PURPOSE_NAMES.FULL_NAME);

    if (fullName) {
        facts.people = [{ fullName }];
    }

    // no relevant data
    if (Object.keys(facts).length === 0) {
        return {};
    }

    brandContent.facts = facts;

    return brandContent;
};

export function dpcToTextFields(
    purposeNames: TemplateField[],
    dpc?: Gallery.Models.Personalization.DesignPersonalizationContext,
): Record<string, string> {
    const matchedPurposes = purposeNames
        .map((p) => dpc?.text?.find((text) => p.purposeName === text.textField.purposeName));

    return matchedPurposes.reduce((result, dpcTextField) => (dpcTextField?.textField.purposeName ? {
        ...result,
        [dpcTextField?.textField.purposeName]: dpcTextField?.textField.text,
    } : result), {});
}

// DO NOT DIRECTLY USE THIS DPC AFTER CONVERSION
// The image dimensions are not accurate and need to be fetched from the Sherbert asset
// TODO: Use brand kit's new endpoint to fetch from the brand profile
// https://vistaprint.atlassian.net/browse/QSP-834
export const brandContentToDpc = (brand: BrandContent | undefined): Gallery.Models.Personalization.DesignPersonalizationContext => {
    const dpc: Gallery.Models.Personalization.DesignPersonalizationContext = {
        designPersonalizationContextVersion: 1,
        text: [],
        colors: [],
        images: [],
    };

    if (!brand) {
        return dpc;
    }

    const fullName = brand.facts?.people?.[0]?.fullName ?? undefined;
    const companyName = brand.facts?.profile?.companyName ?? undefined;
    const logos = brand.facts?.designIdentity?.logos;

    logos?.forEach((logo) => {
        dpc.images?.push({
            image: {
                originalSourceUrl: logo.uds?.originalSourceUrl || '',
                previewUrl: logo.uds?.previewUrl || '',
                printUrl: logo.uds?.printUrl || '',
                // TODO: fetch image dimensions from brand api
                width: 1000,
                height: 1000,
                assetId: logo.assetId || '',
            },
            purpose: 'logo',
        });
    });

    if (fullName) {
        const splitName = splitFullName(fullName);

        dpc.text?.push({
            textField: {
                purposeName: PURPOSE_NAMES.FULL_NAME,
                text: fullName,
            },
        });

        dpc.text?.push({
            textField: {
                purposeName: 'firstname',
                text: splitName.firstname,
            },
        });

        if (splitName.lastname) {
            dpc.text?.push({
                textField: {
                    purposeName: 'lastname',
                    text: splitName.lastname,
                },
            });
        }
    }

    if (companyName) {
        dpc.text?.push({
            textField: {
                purposeName: PURPOSE_NAMES.COMPANY_NAME,
                text: companyName,
            },
        });
    }

    return dpc;
};

export function dpcToAppliedUploads(
    dpc?: Gallery.Models.Personalization.DesignPersonalizationContext,
): Gallery.Models.Personalization.UploadIdentifier[] {
    return dpc?.images?.map((i) => {
        const { assetId, page } = i.image;

        return {
            assetId,
            page,
        };
    }) ?? [];
}

/**
 * Pulls information from a VistaAsset to generate Design Personalization Image Data
 *
 * @param asset A VistaAsset to pull from
 * @param timeout The number of milliseconds to search before giving up when retrieving dimension data
 * @param defaultToPhoto Whether to assume an unclassified image is photo rather than a logo
 * @param pageNumber Which page of the VistaAsset to pull from
 * @returns { id: string; previewUrl: string; printUrl: string; thumbnailUrl: string; height: number; width: number; }
 */
export const convertVistaAssetToDpcImage = async (asset: VistaAsset, dimensionDataTimeout: number, defaultToPhoto: boolean, pageNumber?: number): Promise<Gallery.Models.Personalization.DpcImage> => {
    const { height, width } = await waitForDimensionData(asset, pageNumber ?? 1, dimensionDataTimeout);
    const {
        data,
        print,
        webPreview,
    } = asset;

    let classifiedAsPhoto;

    // getImageInfo is the fast but less accurate way to get photo or logo classification
    // TODO: consider changing this https://vistaprint.atlassian.net/browse/QSP-779
    try {
        classifiedAsPhoto = data ? (await asset.getImageInfo?.(data?.id))?.isPhoto : defaultToPhoto;
    } catch (_error) {
        classifiedAsPhoto = defaultToPhoto;
    }
    const purpose = classifiedAsPhoto || defaultToPhoto ? 'photo' : 'logo';

    return {
        image: {
            originalSourceUrl: asset.getUrl({ includeSignature: true, pageNum: pageNumber ?? 1 }),
            previewUrl: webPreview.getUrl({ includeSignature: true, pageNum: pageNumber ?? 1 }),
            printUrl: print.getUrl({ includeSignature: true }),
            height: height ?? 0,
            width: width ?? 0,
            assetId: data?.id ?? '',
            page: pageNumber,
        },
        purpose,
    };
};

/**
 * Pulls information from a VistaAsset identifed by an UploadIdentifier to generate Design Personalization Image Data
 *
 * @param upload The assetId and page to pull
 * @param timeout The number of milliseconds to search before giving up when retrieving dimension data
 * @param defaultToPhoto Whether to assume an unclassified image is photo rather than a logo
 * @param assetStore The asset store to fetch the VistaAsset from
 * @returns { id: string; previewUrl: string; printUrl: string; thumbnailUrl: string; height: number; width: number; }
 */
export async function selectedUploadToDpcImage(
    upload: Gallery.Models.Personalization.UploadIdentifier,
    dimensionDataTimeout: number,
    defaultToPhoto: boolean,
    assetStore?: VistaAssetStore,
): Promise<Gallery.Models.Personalization.DpcImage | undefined> {
    if (!assetStore) {
        return undefined;
    }
    const { assetId, page } = upload;

    const asset = await assetStore.fetchSingleAsset({ id: assetId });

    await asset.presign();

    return convertVistaAssetToDpcImage(asset, dimensionDataTimeout, defaultToPhoto, page);
}

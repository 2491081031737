import {
    ComponentProps, useCallback, useEffect, useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { DesignTile, ImageProps } from 'client/components/common/DesignTile';
import { PreviewImage } from 'client/components/common/PreviewImage';
import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { useSelector } from 'react-redux';
import { colorSwatchSelector, designsSelector, maxPreviewHeightSelector } from 'client/store/design';
import {
    AnalyticsNames, ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL, PREVIEW_TYPE,
} from 'shared/constants';
import { resolveInitialSwatchId } from 'shared/resolveInitialSwatchId';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { selectQuickViewFilterNames } from 'client/store/quickViewFilter';
import { useQueryClient } from '@tanstack/react-query';
import {
    DESIGN_VARIATIONS_QUERY_KEY_NAME, DESIGN_VARIATIONS_QUERY_SETTINGS,
} from 'client/hooks/useDesignVariations';
import { getBypassApproval } from 'client/store/debug';
import { designVariationsInputSelector } from 'client/store/quickView/selectors';
import { queryDesignVariations } from 'client/queries/queryDesignVariations';
import { galleryImpressionIdSelector } from '~/client/store/analytics/reducer';
import { useGreetingDescription } from '~/client/hooks/useGreetingDescription/useGreetingDescription';
import { useSocialMediaGallery } from '~/client/hooks/useSocialMediaGallery';
import { designPersonalizationContextSelector } from '~/client/store/personalization/selectors';
import { shouldRestorePhotosState } from '~/client/atoms/shouldRestorePhotosAtom';
import { useNewTemplateAltText } from '~/client/hooks/useNewTemplateAltText';
import { NUMBER_OF_IMAGES_WITH_HIGH_PRIORITY } from 'src/client/components/Gallery/PreviewArea/constants';

type DesignTileImageProps = Partial<ComponentProps<typeof DesignTile>['imageProps']> & Pick<ComponentProps<typeof PreviewImage>, 'maxPreviewHeight'>;

export type PropTypes = {
    tileEntityId: string;
    children: (
        tileEntity: State.TileEntity,
        imageProps: ImageProps,
        handleColorSwatchChange: (newDesignId: string) => void,
        colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
        currentColorSwatch: Gallery.Designs.CurrentColorSwatch,
        currentDesignId: string,
        greetingOptions: string,
        greetingTitle: string,
        shouldShowGreetingOptions: boolean,
        shouldHideForSocialMedia: boolean,
        onMouseEnter?: () => void,
    ) => JSX.Element | null;
};

function createTileAnalyticsData(
    tileEntity: State.TileEntity,
): Gallery.Analytics.DesignAnalyticsObject {
    return {
        tileEntity,
        position: tileEntity.position,
        location: AnalyticsNames.DesignTile,
    };
}

export const GalleryTileEntityProvider = (props: PropTypes): JSX.Element | null => {
    const { tileEntityId, children } = props;
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const selectColorSwatch = useSelector(colorSwatchSelector);
    const analytics = useAnalytics();
    const designs = useSelector(designsSelector);
    const tileEntity = tileEntityById(tileEntityId);
    const [currentDesignId, setCurrentDesignId] = useState(resolveInitialSwatchId(tileEntity));
    const shouldHideForSocialMedia = useSocialMediaGallery();
    const dpc = useSelector(designPersonalizationContextSelector);
    const [shouldRestorePhotos] = useRecoilState(shouldRestorePhotosState);

    const {
        greetingTitle,
        greetingOptions,
        shouldShowGreetingOptions,
    } = useGreetingDescription(tileEntity);

    const currentColorSwatch = selectColorSwatch(
        currentDesignId,
        tileEntityId,
        PREVIEW_TYPE.TILE,
        shouldRestorePhotos,
    );

    const previewAlt = useNewTemplateAltText(tileEntity, currentColorSwatch.colorSwatches);
    const quickViewFilters = useSelector(selectQuickViewFilterNames);
    const queryClient = useQueryClient();

    const designVariationsInput = useSelector(designVariationsInputSelector)(
        tileEntity,
        tileEntity.productOptions,
        Object.keys(tileEntity.templateUseCases),
    );
    const maxPreviewHeight = useSelector(maxPreviewHeightSelector);
    const bypassedApproval = useSelector(getBypassApproval);
    const impressionId = useSelector((state: State.GlobalState) => galleryImpressionIdSelector(state, tileEntityId));

    const {
        previewWidth,
        previewsUrls,
    } = currentColorSwatch.renderablePreviews;

    const imageProps: DesignTileImageProps = {
        alt: previewAlt,
        previewWidth,
        previewsUrls,
        maxPreviewHeight,
        bypassedApproval,
    };

    if (tileEntity.position < NUMBER_OF_IMAGES_WITH_HIGH_PRIORITY) {
        imageProps.fetchpriority = 'high';
    }

    // Update currentDesignId when tileEntityId changes to ensure color filters affect the selected color swatch
    useEffect(() => {
        setCurrentDesignId(resolveInitialSwatchId(tileEntity));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tileEntity?.selectedColorSwatchDesignId]);

    const handleColorSwatchChange = useCallback((newDesignId: string): void => {
        const color = designs.byId[newDesignId].color?.split('#')[1];
        const analyticsData = createTileAnalyticsData(tileEntity);
        const colorSwatchPersonalizedDetail = dpc ? 'Tile color selection with personalized design' : '';

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
            eventLabel: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
            eventDetail: colorSwatchPersonalizedDetail,
            ...analytics.buildDesignEngagement({
                engagementAction: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
                selectedDesign: newDesignId,
                color,
                tileEntity: analyticsData.tileEntity,
                colorSwatchObjects: currentColorSwatch.colorSwatches,
                impressionId,
                location: analyticsData.location,
                position: analyticsData.tileEntity.position,
            }),
        });

        setCurrentDesignId(newDesignId);
    }, [designs.byId, tileEntity, dpc, analytics, currentColorSwatch.colorSwatches, impressionId]);

    const onMouseEnter = quickViewFilters.length > 0 ? (): void => {
        const dvQueryKey: DesignVariations.QueryKey = [DESIGN_VARIATIONS_QUERY_KEY_NAME, designVariationsInput];

        queryClient.prefetchQuery(dvQueryKey, queryDesignVariations, DESIGN_VARIATIONS_QUERY_SETTINGS);
    } : undefined;

    return children(
        tileEntity,
        imageProps,
        handleColorSwatchChange,
        currentColorSwatch.colorSwatches,
        currentColorSwatch,
        currentDesignId,
        greetingOptions,
        greetingTitle,
        shouldShowGreetingOptions,
        shouldHideForSocialMedia,
        onMouseEnter,
    );
};

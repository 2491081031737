import { BOOSTS_UPDATE, BoostsUpdateAction } from './actions';

const initialState: State.BoostsState = {
    boostedKeywords: [],
    boostedCategories: [],
};

export function reducer(
    state: State.BoostsState = initialState,
    action: BoostsUpdateAction,
): State.BoostsState {
    switch (action.type) {
        case BOOSTS_UPDATE:
            return action.payload.boosts;
        default:
            return state;
    }
}

export const boostsSelector = (state: State.GlobalState): State.BoostsState => state.boosts;

import {
    Suspense, forwardRef, memo,
} from 'react';
import {
    Box, FluidImage, Spinner,
} from '@vp/swan';
import { TemplateTileImageProps } from '~/client/components/common/TemplateTile/components/TemplateTileImage/interface';
import classnames from 'classnames';
import { TemplateTileFallbackPreview } from '~/client/components/common/TemplateTile/components/TemplateTileFallbackPreview';
import { TILE_PREVIEW_SIZE } from '~/client/constants';

export const TemplateTileImage = memo(forwardRef<HTMLImageElement, TemplateTileImageProps>((
    {
        showSpinner,
        accessibilityText,
        size,
        isError,
        ...imageProps
    },
    ref,
): JSX.Element => (
    <Box className="template-image-wrapper">
        {isError && (
            <TemplateTileFallbackPreview size={size} />
        )}
        {showSpinner && (
            <div className="template-tile-spinner">
                <Spinner accessibleText={accessibilityText} size="standard" />
            </div>
        )}
        <Suspense fallback={null}>
            <FluidImage
                className={classnames(
                    'template-tile-image',
                    {
                        'template-tile-image-large': size === TILE_PREVIEW_SIZE.LARGE,
                        'template-tile-image-hide': isError,
                    },
                )}
                ref={ref}
                {...imageProps}
            />
        </Suspense>
    </Box>
)));

import { useUploadManager } from '@design-stack-vista/upload-components';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { selectedUploadToDpcImage } from '../../../Filters/PersonalizationFilter/dpcConverters';
import { useExpectImagesToBePhotos } from '../../../Filters/PersonalizationFilter/useSpecifyImageCopy';

type DpcImage = Gallery.Models.Personalization.DpcImage;

const DIMENSION_DATA_TIMEOUT_MS = 30000;

export const useConvertUploadsToDpc = (): DpcImage[] | undefined => {
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const [dpcSelectedImages, setDpcSelectedImages] = useState<DpcImage[] | undefined>(undefined);
    const defaultToPhoto = useExpectImagesToBePhotos();
    const { assetStore } = useUploadManager();

    // convert selected uploads to Dpc format
    useEffect(
        () => {
            const getDpcSelectedImages = async (): Promise<void> => {
                const images = await Promise.all(selectedUploads.map(
                    (upload) => selectedUploadToDpcImage(upload, DIMENSION_DATA_TIMEOUT_MS, defaultToPhoto, assetStore),
                ));

                setDpcSelectedImages(images.filter((image): image is DpcImage => !!image));
            };

            getDpcSelectedImages();
        },
        [assetStore, defaultToPhoto, selectedUploads],
    );

    return dpcSelectedImages;
};

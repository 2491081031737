import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
} from '@vp/swan';
import classnames from 'classnames';
import { BrowserZoom } from 'client/components/BrowserZoom';
import { useTranslations } from 'client/hooks/useTranslations';
import { ComponentProps } from 'react';
import { useIsSafariVersion18 } from '~/client/hooks/usePreventScroll';

export interface PropTypes extends ComponentProps<typeof ModalDialog> {
    ariaLabel?: string;
}

export const QuickView = (props: PropTypes): JSX.Element => {
    const {
        ariaLabel, children, className, onRequestDismiss, ...rest
    } = props;
    const localize = useTranslations();
    const isSafari18 = useIsSafariVersion18();

    return (
        <BrowserZoom>
            <ModalDialog
                {...rest}
                className={classnames('quick-view-dialog', className)}
                variant="panel-right"
                onRequestDismiss={onRequestDismiss}
            >
                <ModalDialogContent
                    aria-label={ariaLabel}
                    className={classnames('quick-view-wrapper', { 'quick-view-wrapper-height': isSafari18 })}
                >
                    <ModalDialogCloseButton
                        aria-label={localize('FilterMenuCloseButtonText')}
                        data-section="Gallery Fly-out"
                        data-translation="Fly-Out Closed"
                        visuallyHiddenLabel={localize('FilterMenuCloseButtonText')}
                    />
                    <ModalDialogBody className="quick-view">
                        {children}
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </BrowserZoom>

    );
};

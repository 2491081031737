import { ComponentProps } from 'react';
import {
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
} from '@vp/swan';

import { FilterOption } from 'client/components/Gallery/Filters/FilterOption';
import {
    buildRefinement, refinementAddAndRemove,
} from 'client/store/refinement';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { useDispatch } from 'react-redux';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';

export interface PropTypes {
    options: RenderableFilterOption[];
    title: string;
    refinementDimension: REFINEMENT_DIMENSION;
    SelectionSetProps?: Omit<ComponentProps<typeof SelectionSet>, 'defaultSelectedValue' | 'variant' | 'selectedValue' | 'selectedValues' | 'onSelectedValueChange' | 'onSelectedValuesChange' | 'skin'>;
    selectedValues: SelectedValues;
    shouldShowFilterOption?: (o: RenderableFilterOption) => boolean;
}

export const FilterCheckboxList = (props: PropTypes): JSX.Element => {
    const {
        options,
        title,
        refinementDimension,
        SelectionSetProps,
        selectedValues,
        shouldShowFilterOption = (): boolean => true,
    } = props;

    const dispatch = useDispatch();
    const redirectToL0 = useRedirectToL0();

    const handleSelectedValuesChange = (newSelectedValues: SelectedValues): void => {
        const toAdd: State.Refinement[] = [];
        const toRemove: State.Refinement[] = [];

        for (const [value, isSelected] of Object.entries(newSelectedValues)) {
            const refinement = buildRefinement(value, refinementDimension);

            (isSelected ? toAdd : toRemove).push(refinement);
        }

        if (redirectToL0(toAdd, toRemove)) {
            return;
        }

        dispatch(refinementAddAndRemove(toAdd, toRemove));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <SelectionSet
            aria-label={title}
            className="selection-set-layout-override"
            id={`selection-set-${title}`}
            selectedValues={selectedValues}
            skin="simple-column"
            variant="multi-select"
            onSelectedValuesChange={handleSelectedValuesChange}
            {...SelectionSetProps}
        >
            {options.filter(shouldShowFilterOption)
                .map((o) => (
                    <div className="selection-set-item" key={o.value}>
                        <SelectionSetInput
                            disabled={o.disabled}
                            value={o.value}
                        >
                            <SelectionSetLabel>
                                <FilterOption facetCount={o.facetCount} title={o.title} />
                            </SelectionSetLabel>
                        </SelectionSetInput>
                    </div>
                ))}
        </SelectionSet>
    );
};

FilterCheckboxList.displayName = 'FilterCheckboxList';

import { AnyAction } from 'redux';
import { buildDesignPersonalizationContext, personalizedTextToDpcText } from 'client/store/personalization/util';
import { LOCAL_STORAGE_KEYS } from '~/client/constants';

const INITIAL_STATE: State.PersonalizationState = {
    designPersonalizationContext: undefined,
};

export const PERSONALIZED_TEXT_UPDATE = 'PERSONALIZED_TEXT_UPDATE';
export const DESIGN_PERSONALIZATION_IMAGES_UPDATE = 'DESIGN_PERSONALIZATION_IMAGES_UPDATE';
export const DESIGN_PERSONALIZATION_UPDATE = 'DESIGN_PERSONALIZATION_UPDATE';

function saveToLocalStorage(
    dpc: Gallery.Models.Personalization.DesignPersonalizationContext,
    galleryId: string,
): void {
    const storageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPC + galleryId;

    if (!dpc) {
        // clear local storage
        localStorage.removeItem(storageKey);
        return;
    }

    localStorage.setItem(storageKey, JSON.stringify(dpc));
}

export function reducer(
    state: State.PersonalizationState = INITIAL_STATE,
    action: AnyAction,
): State.PersonalizationState {
    switch (action.type) {
        case PERSONALIZED_TEXT_UPDATE: {
            const newDpcText = personalizedTextToDpcText(action.payload.text);
            const updatedContext = buildDesignPersonalizationContext({
                text: newDpcText,
                images: state.designPersonalizationContext?.images,
            });

            // store updatedContext in dpc session storage
            saveToLocalStorage(updatedContext, action.payload.galleryId);

            return {
                ...state,
                designPersonalizationContext: updatedContext,
            };
        }

        case DESIGN_PERSONALIZATION_IMAGES_UPDATE: {
            const updatedContext = buildDesignPersonalizationContext({
                text: state.designPersonalizationContext?.text,
                images: action.payload.images,
            });

            // store updatedContext in dpc session storage
            saveToLocalStorage(updatedContext, action.payload.galleryId);

            return {
                ...state,
                designPersonalizationContext: updatedContext,
            };
        }
        case DESIGN_PERSONALIZATION_UPDATE: {
            return {
                ...state,
                designPersonalizationContext: action.payload.dpc,
            };
        }
        default:
            return state;
    }
}

import { Brand, useBrandContext } from '@vp/react-brand';
import {
    Box,
    FluidImage,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    SelectionSetTileContents,
    SelectionSetTileImageContainer,
    SelectionSetTileName,
    SelectionSetTilePrice,
    ToggleSwitch,
    ToggleSwitchOffLabel,
    ToggleSwitchOnLabel,
} from '@vp/swan';
import { memo, useState } from 'react';

export const BrandSelector = memo((): JSX.Element | null => {
    const brandContext = useBrandContext();
    const brand = brandContext.activeBrand;
    const [activeBrand, setActiveBrand] = useState<Brand | undefined>();

    return brand ? (
        <Box className="brand-container">
            <SelectionSet selectedValue="" skin="tiles-mini" tileImagePadding="wide">
                <SelectionSetInput
                    value={brand.brandId}
                    onClick={(): void => setActiveBrand(brand.brandId === activeBrand?.brandId ? undefined : brand)}
                >
                    <SelectionSetLabel className="brand-selection-label">
                        <SelectionSetTileImageContainer className="brand-logo-container">
                            <FluidImage
                                alt="placeholder"
                                src={brand.facts?.designIdentity?.logos?.[0]?.uds?.previewUrl || undefined}
                            />
                        </SelectionSetTileImageContainer>
                        <SelectionSetTileContents className="brand-text">
                            <SelectionSetTileName>{brand.facts?.profile?.companyName}</SelectionSetTileName>
                            <SelectionSetTilePrice>{brand.facts?.people?.[0]?.fullName || null}</SelectionSetTilePrice>
                        </SelectionSetTileContents>
                        <ToggleSwitch
                            hideText
                            activated={activeBrand?.brandId === brand.brandId}
                            aria-labelledby="exampleToggleSwitchLabellingElement"
                            className="brand-toggle"
                            onRequestActivatedChange={
                                (): void => setActiveBrand(brand.brandId === activeBrand?.brandId ? undefined : brand)
                            }
                        >
                            <ToggleSwitchOnLabel>On</ToggleSwitchOnLabel>
                            <ToggleSwitchOffLabel>Off</ToggleSwitchOffLabel>
                        </ToggleSwitch>
                    </SelectionSetLabel>
                </SelectionSetInput>
            </SelectionSet>
        </Box>
    ) : null;
});

BrandSelector.displayName = 'BrandSelector';
